import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard, HeaderHome } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import LineLogo from "../assets/icons/line-logo.png";
import GoogleLogo from "../assets/icons/google.png";
import liff from "@line/liff";

const SigninLine = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  useEffect(() => {
    liff
      .init({
        liffId: "2002632700-wPzMX491", // Use own liffId
        // withLoginOnExternalBrowser: true, // Enable automatic login process
      })
      .then(() => {
        //code
        console.log(liff);
        // liff.login();
        handleLineLogin();
      });
  }, []);

  const handleLineLogin = async () => {
    try {
      //
      // liff.login();
      // login call, only when external browser or LINE's in-app browser is used
      if (!liff.isLoggedIn()) {
        liff.login();
      }
      // const profile = await liff.getProfile();
      // console.log("Profile: ", profile);
      if (liff.isLoggedIn()) {
        const profile = await liff.getProfile();
        console.log("Profile: ", profile);
        window.localStorage.setItem("uid", profile.userId);
        window.localStorage.setItem("ufullname", profile.displayName);
        window.localStorage.setItem("uPicture", profile.pictureUrl);
        window.localStorage.setItem("logWith", "LINE");
        navigate("/");
      }

      /*       if (liff.isLoggedIn()) {
        const profile = await liff.getProfile();
        console.log("Profile: ", profile);
        window.localStorage.setItem("uid", profile.userId);
        window.localStorage.setItem("ufullname", profile.displayName);
        window.localStorage.setItem("uPicture", profile.pictureUrl);
        window.localStorage.setItem("logWith", "LINE");
        navigate("/");
      } else {
        liff.login();
        if (liff.isLoggedIn()) {
          const profile = await liff.getProfile();
          console.log("Profile: ", profile);
          window.localStorage.setItem("uid", profile.userId);
          window.localStorage.setItem("ufullname", profile.displayName);
          window.localStorage.setItem("uPicture", profile.pictureUrl);
          window.localStorage.setItem("logWith", "LINE");
          navigate("/");
        }     
      } */

      /*  
       // window.location.reload();
      if (!liff.isLoggedIn()) {
            liff.login();
            console.log(liff);
            alert(JSON.stringify(liff));
            return false;
          }
          const profile = await liff.getProfile();
          console.log("Profile: ", profile);
          window.localStorage.setItem("uid", profile.userId);
          window.localStorage.setItem("ufullname", profile.displayName);
          window.localStorage.setItem("uPicture", profile.pictureUrl);
          navigate("/"); */
    } catch (err) {
      console.log(err);
    }
  };
  /*       const logout = async () => {
        if (liff.isLoggedIn()) {
          liff.logout();
          window.location.reload();
        }
      }; */
  return <div></div>;
};

export default SigninLine;
