import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
} from "../constants/BaseUrl";
import {
  HeaderBack,
  MinorOccupationCard,
  HeaderHome,
  Header,
  NotificationCard,
} from "../components";
import axios from "axios";
import { FaUserCog, FaUserMinus, FaCartPlus, FaGlobe } from "react-icons/fa";
import { GrHelpBook } from "react-icons/gr";
import { GrDocumentVerified } from "react-icons/gr";
import { MdOutlineLiveHelp } from "react-icons/md";
const HomeSettings = () => {
  // const { id,  } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [userId, setUserId] = useState(null);
  const [userAccount, setUserAccount] = useState({});
  const web = "https://giftlikesinfo.com/";

  const readAccount = (id) => {
    axios.get(`${pvuserUrl}readuser.php/${id}/pvacc`).then(function (response) {
      // console.log(response.data);
      setUserAccount(response.data);
    });
  };

  useEffect(() => {
    var id = localStorage.getItem("uid");
    // var user = localStorage.getItem("ufullname");
    setUserId(id);
    readAccount(id);
  }, []);

  //PrivacyPolicy.js
  //TermOfService.js
  //CallCenter.js
  //HelpCenter.js

  // console.log("User Setting: ", userId);

  return (
    <>
      <Header />
      <div className="overflow-y-scroll">
        {/* -------  */}

        <div className="mt-[4.5rem]">
          {userId === null ? (
            <>
              {/*             <div className="flex justify-center items-center rounded-sm border border-[#504f4f] p-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1">
              <strong className="font-medium text-white">
                กรุณาเข้าระบบก่อนตั้งค่า
              </strong>
            </div> */}
              <div className="mt-[4.5rem] mb-2">
                <NotificationCard title={"แจ้งเตือน"} text={"กรุณาเข้าระบบ"} />
              </div>
            </>
          ) : null}
        </div>
        <div className={`${userId !== null ? "mt-[4.5rem]" : ""} mx-4`}>
          <div className="my-1 p-2">
            <strong className="font-medium text-white">บัญชีผู้ใช้</strong>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border border-[#504f4f] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link
              to={`${userId ? "/userprofile" : ""}`}
              className="flex gap-4 items-center"
            >
              {/*               <FaUserCog
                fontSize={20}
                color="#FFF"
                className=" bg-sky-500 rounded-full flex justify-center items-center w-[2.75rem] h-[2.75rem]"
              /> */}
              <img
                src={require("../assets/setting/1.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">บัญชีผู้ใช้งาน</strong>
            </Link>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border border-[#504f4f] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link
              to={`${userId ? "/deleteaccount" : ""}`}
              className="flex gap-4 items-center"
            >
              <img
                src={require("../assets/setting/2.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">ลบบัญชีผู้ใช้งาน</strong>
            </Link>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border border-[#504f4f] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link
              to={`${userId ? "/VIPPackage" : ""}`}
              className="flex gap-4 items-center"
            >
              <img
                src={require("../assets/setting/3.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">VIP Package</strong>
            </Link>
          </div>
          <div className="my-1 p-2">
            <strong className="font-medium text-white">เกี่ยวกับ</strong>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border border-[#504f4f] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link to={"/registerMember"} className="flex gap-4 items-center">
              <img
                src={require("../assets/setting/4.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">สมัครลิงค์แนะนำอาชีพ</strong>
            </Link>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border border-[#504f4f] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link to={"/privacyPolicy"} className="flex gap-4 items-center">
              <img
                src={require("../assets/setting/5.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">นโยบายความเป็นส่วนตัว</strong>
            </Link>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border border-[#504f4f] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link to={"/termOfService"} className="flex gap-4 items-center">
              <img
                src={require("../assets/setting/6.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">เงื่อนไขและข้อกำหนด</strong>
            </Link>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border border-[#504f4f] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link
              to={`${userId ? "/callCenter" : ""}`}
              className="flex gap-4 items-center"
            >
              <img
                src={require("../assets/setting/7.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">ร้องเรียน</strong>
            </Link>
          </div>
          <div
            className={`w-[${windowSize[0]}px] rounded-sm border border-[#504f4f] p-1 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <Link to={"/helpCenter"} className="flex gap-4 items-center">
              <img
                src={require("../assets/setting/8.png")}
                alt={""}
                className="w-[50px] h-[50px] rounded-full object-cover"
              />
              <strong className="font-normal">ช่วยเหลือ</strong>
            </Link>
          </div>
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default HomeSettings;
