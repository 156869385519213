import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgMinorUrl,
  shopUrl,
  imgShopUrl,
  imgAdvUrl,
  imgProviceUrl,
} from "../constants/BaseUrl";

const TopProviceCard = ({ item }) => {
  // TopProviceCard
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  // console.log("Top: ", item);
  const handleClick = () => {
    navigate("/proviceoccupation", { state: item });
  };
  return (
    <>
      <div className="relative mt-[1rem] cursor-pointer rounded-xl shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
        <button onClick={handleClick}>
          <div className="w-[150px] h-[120px] rounded-lg overflow-hidden">
            <img
              //   src={item.image}
              src={`${imgProviceUrl}${item.photo}`}
              alt={item.thName}
              className="w-full h-full object-cover"
            />
            <div className="absolute left-0 bottom-1 p-4 bg-[rgba(0,0,0,0.5)] text-center  w-full">
              <strong className="text-sm text-white">{item.thName}</strong>
            </div>
          </div>
        </button>
        {/* <div>{JSON.stringify(item)}</div> */}
      </div>
    </>
  );
};

export default TopProviceCard;
