import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import { HiMenuAlt1 } from "react-icons/hi";
import { RiMapPinLine } from "react-icons/ri";
import { IoMdLogOut, IoIosLogOut } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";
import { FaUserLock } from "react-icons/fa";
import liff from "@line/liff";
const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [auth, setAuth] = useState(null);
  const [userId, setUserId] = useState("");
  const [loginWith, setLoginWith] = useState("");
  useEffect(() => {
    liff
      .init({
        liffId: "2002632700-wPzMX491", // Use own liffId
        // withLoginOnExternalBrowser: true, // Enable automatic login process
      })
      .then(() => {
        //code
        console.log(liff);
        // liff.login();
        // handleLineLogin();
      });
    var id = localStorage.getItem("uid");
    var user = localStorage.getItem("ufullname");
    var login = localStorage.getItem("logWith");
    // console.log("user: ", user);
    setAuth(user);
    setUserId(id);
    setLoginWith(login);
    /*     if (user === null) {
      navigate("/signin");
    } */
  });
  const LogOut = () => {
    // setIsClicked(initialState);
    localStorage.removeItem("uid");
    localStorage.removeItem("ufullname");
    localStorage.removeItem("uemail");
    localStorage.removeItem("uispolicy");
    localStorage.removeItem("logWith");
    localStorage.clear();
    if (loginWith === "LINE" || liff.isLoggedIn()) {
      liff.logout();
      window.location.reload();
    }

    // window.location.reload();
    navigate("/");
    /*     if (loginWith === "LINE" || liff.isLoggedIn()) {
      liff.logout();
     
      localStorage.removeItem("uid");
      localStorage.removeItem("ufullname");
      localStorage.removeItem("uemail");
      localStorage.removeItem("uispolicy");
      localStorage.removeItem("logWith");
      localStorage.clear();

      navigate("/homeservices");
    } else {
      localStorage.removeItem("uid");
      localStorage.removeItem("ufullname");
      localStorage.removeItem("uemail");
      localStorage.removeItem("uispolicy");
      localStorage.removeItem("logWith");
      localStorage.clear();

      navigate("/homeservices");
    } */

    // window.location.reload();
  };
  return (
    <div className="fixed top-0 w-full mx-auto z-50 pt-3 pb-3 px-2 bgDark overflow-hidden flex justify-between items-center  md:w-[24.375rem] mb-1">
      <span className="cursor-pointer bg-sky-500 rounded-full flex justify-center items-center w-[2.75rem] h-[2.75rem]">
        <RiMapPinLine fontSize={30} color="#FFF" />
      </span>
      <div>
        <span className="text-sky-500 font-medium text-2xl">Gift</span>Likes
      </div>
      <div className="bg-gray-200 w-[2.75rem] h-[2.75rem] rounded-full block">
        {auth !== null ? (
          <>
            <button onClick={LogOut}>
              <span className="cursor-pointer bg-sky-500 rounded-full flex justify-center items-center w-[2.75rem] h-[2.75rem]">
                <IoLogOutOutline fontSize={30} color="#FFF" />
              </span>
            </button>
          </>
        ) : (
          <>
            <Link to={"/signin"}>
              <span className="cursor-pointer bg-sky-500 rounded-full flex justify-center items-center w-[2.75rem] h-[2.75rem]">
                <FaUserLock fontSize={30} color="#FFF" />
              </span>
            </Link>
          </>
        )}
        {/*         <img
          src="https://source.unsplash.com/200x200?face"
          className="rounded-full w-full h-full object-cover"
        /> */}
      </div>
    </div>
  );
};

export default Header;
