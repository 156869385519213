import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgProviceUrl,
  shopUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard, ProviderCard } from "../components";
import axios from "axios";

const ProviceOccupation = () => {
  //   const { item } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [companyInfoData, setCompanyInfoData] = useState([]);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const item = location.state;
  // console.log("Hi Item: ", item);
  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    searchCompany(searchTerm);
    setSearchItem(searchTerm);
  };

  function searchCompany(txtOcc) {
    setIsLoading(true);
    /*     const result = detail.replace(/[^0-9]/g, '');
    let result2 = result.slice(-5);
    console.log(result2); */
    axios
      // .get(`${shopUrl}searchoccupation.php/${txtOcc}`)
      .post(`${shopUrl}searchallserviceonprovice.php`, {
        searchText: txtOcc,
        provice: item.thName,
      })
      .then(function (response) {
        /*         console.log("Search---------");
        const data = response.data;
        console.log("Type: ", typeof data);
        console.log(response?.data);
        console.log("---------Search"); */
        setCompanyInfoData(response?.data);
        // memberID
        // readLogo(response?.data.memberID);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    searchCompany(searchItem);
  }, []);
  return (
    <>
      <HeaderBack />

      {/* -------  */}

      <div className="relative mt-[6rem] mx-2">
        <div>
          <input
            type="text"
            className="w-full mb-2 border py-2 px-3 text-grey-darkest"
            value={searchItem}
            onChange={handleInputChange}
            placeholder={`ค้นหาบริการจังหวัด${item.thName}`}
          />
        </div>
        <div
          className={`w-[${windowSize[0]}px] h-[${
            windowSize[0] * 0.5
          }px] rounded-lg overflow-hidden`}
        >
          <img
            //   src={item.image}
            src={`${imgProviceUrl}${item.photo}`}
            alt={item.thName}
            className={`w-full h-[${windowSize[0] * 0.5}px] object-cover`}
          />
          <div className="absolute left-0 bottom-1 p-4 bg-[rgba(0,0,0,0.5)] text-center  w-full">
            <strong className="text-sm text-white">{item.thName}</strong>
          </div>
        </div>
      </div>
      {/* ---------  */}
      <div className="overflow-y-scroll mt-4">
        <div className="grid grid-cols-1 gap-4 mx-5 pb-[6rem]">
          {companyInfoData.map((item, idx) => (
            <ProviderCard
              key={`minor_${idx}`}
              item={item}
              width={windowSize[0]}
            />
          ))}
        </div>
        {/* ---------  */}

        {/* -------  */}
      </div>
      <div className="mt-[200px]"></div>
    </>
  );
};

export default ProviceOccupation;
