import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";

import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
} from "../constants/BaseUrl";
import axios from "axios";
// import MainOccupationCard from "./../components/MainOccupationCard";
import {
  MainOccupationCard,
  NearProviceCard,
  TopProviceCard,
  Header,
  HeaderSearch,
} from "../components";

const HomeServices = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [mainoccData, setMainOccData] = useState([]);
  const [proviceData, setProviceData] = useState([]);
  const [minoroccData, setMinorOccData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const readMainocc = () => {
    setIsLoading(true);
    //export const pvuserUrl = `${url}users/`; //u
    axios.get(`${pvuserUrl}readmainocc.php`).then(function (response) {
      // console.log("Main Occ: ", response.data);
      setMainOccData(response.data);
      setIsLoading(false);
    });
  };

  const readMinerocc = () => {
    axios.get(`${pvuserUrl}readminoroccall.php`).then(function (response) {
      // console.log(response.data);
      setIsLoading(true);
      setMinorOccData(response.data);
      // setData(response.data);
      // setFullData(response.data);
      /*       setFilteredData(response.data);*/
      // setIsLoading(false);
      setIsLoading(false);
    });
  };
  //export const proviceUrl = `${url}proviceapp/`;
  const readProvice = (id) => {
    setIsLoading(true);
    axios.get(`${proviceUrl}readproviceall.php`).then(function (response) {
      // console.log(response.data);
      setProviceData(response.data); //proviceData
      setIsLoading(false);
    });
  };

  useEffect(() => {
    readMainocc();
    readMinerocc();
    readProvice();
  }, []);

  // useEffect(
  // readMainocc();
  // readProvice();
  // useCallback(() => {
  // setIsLoading(true);
  // getGPS();
  // readProviceCondition(place.description);
  // readMainocc();
  // readProvice();
  // readMinerocc();
  // setIsLoading(false);
  // return () => {};
  // }, [])
  //); //navigate
  return (
    <>
      <HeaderSearch />
      <div className="overflow-y-scroll mx-auto">
        <div className="no-scrollbar flex gap-3 mx-2 overflow-x-auto">
          {mainoccData.map((item, idx) => (
            <MainOccupationCard key={`main_${idx}`} item={item} />
          ))}
        </div>
        <div className="mt-[1rem] mx-[1rem]">
          <strong>บริการใกล้คุณ</strong>
        </div>
        <div className="no-scrollbar flex gap-3 mx-2 overflow-x-auto">
          {minoroccData.map((item, idx) => (
            <NearProviceCard key={`minor${idx}`} item={item} />
          ))}
        </div>
        <div className="mt-[1rem] mx-[1rem]">
          <strong>ข้อมูลบริการทั้งหมด</strong>
        </div>
        <div className="no-scrollbar flex gap-3 mx-2 overflow-x-auto">
          {proviceData.map((item, idx) => (
            <TopProviceCard key={`provice_${idx}`} item={item} />
          ))}
        </div>
        <div className="mt-[200px]"></div>
      </div>
    </>
  );
};

export default HomeServices;
