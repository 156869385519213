import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgMinorUrl,
} from "../constants/BaseUrl";

const MinorOccupationCard = ({ item, urlLink, width }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  let myURL = "";
  /*   useEffect(() => {
    if (emergency == 0) {
      myURL = "ProviderEmergencyList";
    } else {
      myURL = "ProviderList";
    }
  }, [navigate]);rounded-lg overflow-hidden mb-1 relative */
  return (
    <Link to={`/${urlLink}/${item?.id}`}>
      <div
        className={`relative w-[${width}px] h-[${
          width * 0.5
        }px] cursor-pointer rounded-xl overflow-hidden shadow-[0px_4px_14px_rgba(0,0,0,0.04)]`}
      >
        <img
          src={`${imgMinorUrl}${item.photo}`}
          alt={item.photo}
          className={`w-full h-[${width * 0.5}px] object-cover overflow-hidden`}
        />
        <div className="absolute left-0 bottom-1 p-4 bg-[rgba(0,0,0,0.5)] text-center  w-full">
          <strong className="text-sm text-white">{item.thName}</strong>
        </div>
      </div>
    </Link>
  );
};

export default MinorOccupationCard;
