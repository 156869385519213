import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  searchcompanyUrl,
  imgDocUrl,
  pvshopUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard, PriceQuoteCard } from "../components";
import axios from "axios"; //PriceQuoteCard
import Swal from "sweetalert2";
import { GrDocumentUpdate } from "react-icons/gr";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { BsPlusCircle } from "react-icons/bs";
import { HiOutlinePlus } from "react-icons/hi";
import { MdOutlineFileDownload } from "react-icons/md";

const PricequoteContactList = () => {
  const { id } = useParams();
  /*   const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location; */
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [subject, setSubject] = useState("");
  const [yourDiscription, setYourDiscription] = useState("");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  /*   const item = location.state;
  const { contactId, comId, uid, memId } = item; */
  /*   contactId: contactId,
  comId: item?.id,
  uid: userId,
  memId: item?.memberID, */
  //   console.log("Hi Item: ", item);
  const [priceQData, setPriceQData] = useState([]);

  function readpriceQ(conId) {
    axios
      .get(`${searchcompanyUrl}readpricequote.php/${conId}`)
      .then(function (response) {
        // console.log(response.data);
        setPriceQData(response.data);
      });
  }

  useEffect(() => {
    // readuserInfo(uid);
    readpriceQ(id);
    // const { contactId, comId, uid, memId } = item;
  }, []);

  // console.log("price: ", priceQData);

  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}
        {/*         <div className="mt-[4rem]">
      Contact:{contactId}, Company:{comId}, User:{uid}, Memberx:{memId}{" "}
    </div> */}
        <div className="mt-[5rem]">
          {/*           <div className="flex justify-end  px-1 mb-4">
            <button
              onClick={gotoAddPricequote}
              className="cursor-pointer px-2 py-1 bg-sky-500 text-white rounded-sm flex justify-center items-center"
            >
              <HiOutlinePlus size={20} />
              <span className="ml-1">ออกใบเสนอราคา</span>
            </button>
          </div> */}
          <div className="">
            {/* <div>Contact ID:{id}</div> */}
            {/* -----------  */}
            {/* {JSON.stringify(docContactData)} */}
            {Object.keys(priceQData).length !== 0 ? (
              <>
                {priceQData.map((pq, idx) => {
                  return (
                    <PriceQuoteCard key={idx} data={pq} width={windowSize} />
                  );
                })}
              </>
            ) : (
              <></>
            )}
            {/* -----------  */}
          </div>
          {/* -----------  */}

          {/* -----------  */}
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default PricequoteContactList;
