import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  searchcompanyUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard } from "../components";
import axios from "axios";
import Swal from "sweetalert2";

const SendContact = () => {
  // const { id,  } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [subject, setSubject] = useState("");
  const [yourDiscription, setYourDiscription] = useState("");
  const [fileResponse, setFileResponse] = useState(null);
  const [commentData, setCommentData] = useState([]);
  const [auth, setAuth] = useState(null);
  const [userId, setUserId] = useState(null);
  const item = location.state;
  const { comId, type, title } = item;
  // console.log("Hi Item: ", item);
  const readComment = (companyId, uid, type) => {
    axios
      .get(`${searchcompanyUrl}readcomments.php/${companyId}/${uid}/${type}`)
      .then(function (response) {
        // console.log(response.data);
        setCommentData(response.data);
      });
  };

  useEffect(() => {
    var id = localStorage.getItem("uid");
    var user = localStorage.getItem("ufullname");
    // console.log("user: ", user);
    setAuth(user);
    setUserId(id);
    if (id !== null) {
      setAuth(user);
      setUserId(id);
      // console.log("Login: ", id);
    } else {
      setAuth("");
      setUserId(0);
      // console.log("No Login: ", id, userId);
    }
    readComment(comId, id, type);
  }, []);

  const onSend = async () => {
    try {
      if (subject !== "" && yourDiscription !== "") {
        axios
          .post(`${searchcompanyUrl}addcoment.php`, {
            userId: userId,
            comId: comId,
            subject: subject,
            description: yourDiscription,
            type: type,
          })
          .then(function (response) {
            console.log(response.data);
            readComment(comId, userId, type);
            setSubject("");
            setYourDiscription("");
            Swal.fire({
              position: "center",
              icon: "success",
              title: "บันทึกข้อมูลเรียบร้อยแล้ว",
              showConfirmButton: false,
              timer: 1500,
            });
            // Alert.alert('แจ้งเตือน', 'บันทึกข้อมูลเรียบร้อยแล้ว');
            // navigation.dispatch(StackActions.replace('signin'));
          });
        // Alert.alert('แจ้งเตือน', 'บันทึกข้อมูลเรียบร้อยแล้ว');
      } else {
        // Alert.alert("แจ้งเตือน", "กรุณาป้อนข้อมูลให้ครบ");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}
        <div className="mt-[6rem]">
          <div className="text-center">
            <strong>{title}</strong>
          </div>
          <div
            className={`w-[${windowSize[0]}px] h-[${windowSize[1]}px] rounded-sm border border-[#504f4f] p-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
          >
            <div className="mb-2 flex flex-col gap-2 md:flex-row">
              <div className="mb-2 flex-1">
                <label>ชื่อเรื่อง</label>
                <input
                  type="text"
                  name="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  className="w-full rounded bg-[#504f4f] py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
              {/* //firstname,lastname,phone,email,password,pltype */}
              <div className="mb-2 flex-1">
                <label>รายละเอียด</label>
                <input
                  type="text"
                  name="yourDiscription"
                  value={yourDiscription}
                  onChange={(e) => setYourDiscription(e.target.value)}
                  className="w-full rounded bg-[#504f4f] py-3 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                />
              </div>
            </div>

            {/* mpackage id,title,shortdetail,longdetail,price,period,unit,created_at onSend*/}
            <div className="flex justify-end">
              <button
                onClick={onSend}
                disabled={isLoading}
                type="submit"
                className="w-full text-center bg-sky-500 text-white py-2.5 rounded-sm"
              >
                {isLoading ? <>กรุณารอซักครู่..</> : <>บันทึก</>}
              </button>
            </div>
          </div>
        </div>

        <div className="mt-[1rem]">
          {commentData.map((comment, idx) => (
            <div
              key={idx}
              className={`w-[${windowSize[0]}px] rounded-lg border border-[#504f4f] p-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
            >
              <div>
                <strong className="block text-sm font-medium">
                  {comment.subject}
                </strong>
                <p className="block text-sm font-normal">
                  {comment.description}
                </p>
                <strong className="block text-sm font-medium">
                  {comment.anssubject ? comment.anssubject : "-"}
                </strong>
                <p className="block text-sm font-normal">
                  {comment.ansdetail ? comment.ansdetail : "-"}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default SendContact;
