import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  searchcompanyUrl,
} from "../constants/BaseUrl";
import {
  HeaderBack,
  MinorOccupationCard,
  HeaderHome,
  Header,
} from "../components";
import axios from "axios";

const HomeContact = () => {
  // const { id,  } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [commentData, setCommentData] = useState([]);
  const [userId, setUserId] = useState("");
  const readComment = (userId, type) => {
    axios
      .get(`${searchcompanyUrl}readusercomments.php/${userId}/${type}`)
      .then(function (response) {
        // console.log(response.data);
        setCommentData(response.data);
      });
  };

  useEffect(() => {
    var uid = localStorage.getItem("uid");
    setUserId(uid);
    readComment(uid, 1);
  }, []);

  // console.log("List Comment Admin: ", commentData);
  return (
    <>
      <Header />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}

        <div className="mt-[6rem]">
          {/* ------  */}
          {Object.keys(commentData).length !== 0 ? (
            <>
              {commentData.map((item, idx) => (
                <div
                  key={idx}
                  className={`w-[${windowSize[0]}px] rounded-sm border border-[#504f4f] p-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
                >
                  <div className="flex items-center gap-2">
                    <div className="w-[20px] h-[20px] bg-red-500 rounded-full overflow-hidden mb-1"></div>
                    <div className="flex-1 w-full">
                      <p className="text-sm font-medium">{item?.subject}</p>
                      <p className="text-sm font-medium">{item?.description}</p>
                    </div>
                  </div>
                  <div>
                    <h3 className="mt-2">รายละเอียดการติดต่อกลับ</h3>
                    <p className="text-sm font-medium">
                      {item?.anssubject ? item?.anssubject : "-"}
                    </p>
                    <p className="text-sm font-medium">
                      {item?.ansdetail ? item?.ansdetail : "-"}
                    </p>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <div
                className={`w-[${windowSize[0]}px] h-[${
                  windowSize[1] * 0.5
                }px] rounded-lg overflow-hidden relative`}
              >
                <img
                  src={require("../assets/images/no-8.png")}
                  alt={""}
                  className={`w-[${windowSize[0]}px] h-[${
                    windowSize[1] * 0.5
                  }px] object-cover`}
                />
                <div className="absolute left-0 bottom-1 p-4 bg-[rgba(0,0,0,0.5)] text-center  w-full">
                  <strong className="text-sm text-white">
                    ไม่มีรายการติดต่อ
                  </strong>
                </div>
              </div>
            </>
          )}
          {/* ------  */}
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default HomeContact;
