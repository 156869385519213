import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard } from "../components";
import axios from "axios";
const MinorOccupation = () => {
  const { id, emergency } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [minoroccData, setMinorOccData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [myURL, setMyURL] = useState("");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  // let myURL = "";
  /*   useEffect(() => {
    if (emergency === 0) {
      myURL = "provideremergencyList";
    } else {
      myURL = "providerlist";
    }
  }, [navigate, emergency]); */
  const handleClick = () => {
    // 👇️ replace set to true
    navigate("/about", { replace: true });
  };
  const readMinerocc = (id) => {
    axios.get(`${pvuserUrl}readminorocc.php/${id}`).then(function (response) {
      // console.log(response.data);
      setMinorOccData(response.data);
    });
  };

  useEffect(() => {
    if (emergency == 0) {
      // myURL = "provideremergencyList";
      setMyURL("provideremergencyList");
    } else {
      // myURL = "providerlist";
      setMyURL("providerlist");
    }
    console.log("Em: ", myURL);
    readMinerocc(id);
  }, [navigate]);
  /*   console.log("xys123456: ", minoroccData);
  console.log("Emergency: ", emergency, " URL: ", myURL); */
  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}

        <div className="mt-[4.5rem]">
          <div className="grid grid-cols-1 gap-4 mx-5 pb-[6rem]">
            {minoroccData.map((item, idx) => (
              <MinorOccupationCard
                key={`minor_${idx}`}
                item={item}
                urlLink={myURL}
                width={windowSize[0]}
              />
            ))}
          </div>
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default MinorOccupation;
