import React, { useState, useEffect, useContext } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgMinorUrl,
  imgShopUrl,
  memberUrl,
} from "../constants/BaseUrl";
import {
  HeaderBack,
  MinorOccupationCard,
  LogoCard,
  SocialMediaCard,
} from "../components";
import axios from "axios";
import { HiMenuAlt1, HiChevronLeft } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import { BiLike } from "react-icons/bi";
import { FaRegCommentDots, FaMapMarkerAlt } from "react-icons/fa";

import { BsShare } from "react-icons/bs";
import { AiOutlineSend } from "react-icons/ai";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { TiContacts } from "react-icons/ti";
import { GrGallery } from "react-icons/gr";
import { UserLocationContext } from "../context/UserLocationContext";

const ProviderContactProfile = () => {
  // const { id, emergency } = useParams();
  // const item = useParams();
  // const location = useLocation();

  // get userId
  // let userId = location.state.userId;

  const uselocation = useLocation();
  const navigate = useNavigate();
  // const { pathname } = location;
  const [userId, setUserId] = useState("");
  const [minoroccData, setMinorOccData] = useState({});
  const [socialData, setSocialData] = useState([]);
  const [logoData, setLogoData] = useState([]);
  // const [item, setItem] = useState(location.state);
  const [data, setData] = useState(uselocation.state.item);
  const longdo_key = "6b75448d3c0808af052906060b770d1c";
  // const [locations, setLocations] = useState(null);
  const [distance, setDistance] = useState(null);
  const { location, setLocation } = useContext(UserLocationContext);
  /*   console.log(location);
  console.log(location.state); */

  /*   if (location.state.item !== null) {
    setItem(location.state.item);
  }
  
  
    const history = useHistory();
  const location = useLocation();
  const reload = () => {
    history.push(location.pathname);
  };
  */

  // const item = location.state.item;
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  const formatDistance = (nm) => {
    if (nm >= 1000) {
      return Math.round(nm / 1000.0) + " กิโลเมตร";
    } else if (nm >= 100) {
      return Math.round(nm) + " เมตร";
    } else {
      return nm.toFixed(1) + " เมตร";
    }
  };

  var map;
  var marker;
  var marker2;
  var longdo = window.longdo;
  const mapCallback = () => {
    console.log("mapCallback");

    // var currentLocation = map.location();
    // console.log("Location mapCallback: ", location);
    //{lon: 98.99211228493506, lat: 18.81642135851569}
    //273 ถ.ป่าตัน ต.ป่าตัน อ.เมืองเชียงใหม่ จ.เชียงใหม่ 50300
    //{lon: 98.99370767176151, lat: 18.8298221084872}
    // console.log("Longdo object: ", longdo);
    /*     {
      lon: location?.longitude,
      lat: location?.latitude,
    }, */
    /*       {
        lon: 100.64842293068324,
        lat: 13.787791382430747,
      }, */

    //13.787791382430747, 100.64842293068324
    marker = new window.longdo.Marker(
      {
        lon: data?.longitude,
        lat: data?.latitude,
      },
      {
        title: "Marker 1",
        // detail: "Drag me",
        draggable: true,
      }
    );

    map = new window.longdo.Map({
      zoom: 12,
      location: {
        lon: data?.longitude,
        lat: data?.latitude,
      },
      placeholder: document.getElementById("longdo-map"),
      lastView: false,
    });
    map.Event.bind("ready", function () {
      //longitude,latitude
      // let mylocation = map.location(); // Cross hair location
      let marker1 = { lat: location?.latitude, lon: location?.longitude };
      let marker2 = { lat: data?.latitude, lon: data?.longitude };
      let mydistance = longdo.Util.distance([marker1, marker2]); //data
      setDistance(formatDistance(mydistance));
      /*       console.log(
        `Latitude2=>: ${locations?.latitude}, Longitude2=>: ${locations?.longitude}`
      ); */
      // console.log("Ready Location: ", mylocation);
      // map.location()
      /*       console.log("M1: ", marker1);
      console.log("M2: ", marker2);
      console.log(formatDistance(mydistance), mydistance); */
      // console.log(data?.latitude, data?.longitude);
    });
    /*     map.Event.bind("overlayClick", function (overlay) {
      console.log(overlay);
    }); */
    /*     map.Event.bind(longdo.EventName.OverlayClick, function (overlay) {
      console.log("overlay");
    }); */
    /*     location: {
      lon: location?.longitude,
      lat: location?.latitude,
    }, */

    /*     location: {
      lon: 100.64842293068324,
      lat: 13.787791382430747,
    }, */

    map.Event.bind(longdo.EventName.OverlayClick, function (overlay) {
      var mouseLocation = map.location(longdo.LocationMode.Pointer);
      // console.log("OverlayClick: ", mouseLocation);
      console.log(overlay);
    });
    //OverlayDrop
    map.Event.bind(longdo.EventName.OverlayDrop, function (overlay) {
      var mouseLocation = map.location(longdo.LocationMode.Pointer);
      // console.log("OverlayDrop: ", mouseLocation);
      // setSearchLocation(mouseLocation);
      /*       console.log("OverlayDrop: ", overlay);
      console.log("lon: ", mouseLocation.lon);
      console.log("lat: ", mouseLocation.lat); */
      // findOverlayDrop(mouseLocation.lon, mouseLocation.lat);
      //13.787791382430747, 100.64842293068324
      // findOverlayDrop(100.64842293068324, 13.787791382430747);
    });
    map.Event.bind(longdo.EventName.OverlayMove, function (overlay) {
      // var mouseLocation = map.location(longdo.LocationMode.Pointer);
      // console.log("Mouse locationxxx: ", mouseLocation);
      // setSearchLocation(mouseLocation);
      /*       if (mouseLocation) {
        readGeocoding();
      } */
    });
    // map.location(new window.longdo.LocationMode.Geolocation());
    map.Overlays.add(marker);
    // map.Overlays.add(marker2);
  };

  //https://api.longdo.com/RouteService/json/route/guide?
  //flon=100.426157&flat=13.686867&tlon=100.457989&tlat=13.712259&mode=t&type=25&locale=th&key=[YOUR_KEY_API]

  //latitude,longitude
  const readMinerocc = (id) => {
    axios
      .get(`${pvuserUrl}readminoroccbyid.php/${id}`)
      .then(function (response) {
        // console.log(response.data);
        setMinorOccData(response.data);
      });
  };

  const readSocial = (id) => {
    axios.get(`${pvuserUrl}readsocial.php/${id}`).then(function (response) {
      setSocialData(response.data);
    });
  };

  const readLogo = (id) => {
    //readlogomember.php
    //logoData, setLogoData
    //readlogoformember.php
    axios
      .get(`${memberUrl}readlogoofmember.php/${id}`)
      .then(function (response) {
        setLogoData(response.data);
      });
  };

  useEffect(() => {
    const existingScript = document.getElementById("longdoMapScript");
    var user = localStorage.getItem("uid");
    /*     if (item !== null) {
      setData(item.item);
    } */
    if (existingScript) mapCallback();

    // console.log("user: ", user);
    setUserId(user);
    readMinerocc(data?.minorOccId);
    readSocial(data?.id);
    readLogo(data?.memberID);
  }, [data]);

  /*   useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords);
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setLocations({ latitude: latitude, longitude: longitude });
        console.log(`Latitude=>: ${latitude}, Longitude=>: ${longitude}`);

      });
    } else {

      console.log("Geolocation not supported");
    }
  }, []);  */
  /*   console.log("Item: ", item);
  console.log("minorOccId: ", item.minorOccId);
  console.log("minorOcc: ", minoroccData);
  console.log("Image: ", item?.companyPhoto);
  console.log("So: ", socialData); */

  //usercontact
  const gotoUserContact = () => {
    /*     const itemData = {
      comId: item?.id,
      type: 1,
      title: "Contact",
    }; */
    navigate("/usercontact", { state: data });
  };
  const gotoUserChat = () => {
    /*     const itemData = {
      comId: item?.id,
      type: 1,
      title: "Contact",
    }; */
    navigate("/userchat", { state: data });
  };

  console.log(data);
  return (
    <>
      {/* <HeaderBack /> */}
      <div className="overflow-y-scroll">
        {/* -------  */}

        <div className="relative h-[270px]">
          <div className="w-full h-[180px] overflow-hidden">
            <img
              src={`${imgMinorUrl}${minoroccData.photo}`}
              alt={data.name}
              className={`w-full h-full object-cover`}
            />
          </div>
          <div className="absolute bg-slate-950/50 top-0 left-0 w-full h-[180px]"></div>
          <div className="absolute top-2 left-2">
            <button
              onClick={() => navigate(-1)}
              className="cursor-pointer bg-white rounded-full flex justify-center items-center w-[2.75rem] h-[2.75rem]"
            >
              <HiChevronLeft fontSize={30} color="#504f4f" />
            </button>
          </div>

          <div className={`absolute top-[100px] left-0 w-full`}>
            <div className="w-full px-4">
              {data?.mainOcc !== "ฉุกเฉิน" ? (
                <>
                  <LogoCard item={data} />
                </>
              ) : null}
            </div>
            <div className="w-full flex px-2">
              <div className="w-[80px] h-[80px] rounded-full overflow-hidden mb-1">
                {data?.companyPhoto !== null ? (
                  <>
                    <img
                      src={`${imgShopUrl}${data?.companyPhoto}`}
                      alt={data.name}
                      className="w-[80px] h-[80px] rounded-full object-cover"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={require("../assets/images/doc-16.png")}
                      alt={data.name}
                      className="w-[80px] h-[80px] rounded-full object-cover"
                    />
                  </>
                )}
              </div>
              <div className="bg-[#1F212C] p-2 flex-1 w-full ml-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] rounded-md overflow-hidden">
                <strong className="block text-sm font-medium">
                  {data?.mainOcc} {" | "}
                  {data?.minorOcc}
                </strong>
                <strong className="block text-sm font-medium">
                  {data?.companyName}
                </strong>
                <p className="text-sm font-medium">{data?.companyDetail}</p>
                <strong className="flex block text-lg gap-1 items-center">
                  <FiPhoneCall fontSize={18} />
                  {data?.phone ? data?.phone : "ไม่ระบุเบอร์โทร"}
                  {/* {item?.phone ? item?.phone.slice(0, 7) + "xxx" : ""} */}
                </strong>
                {/*                 <div>
                  <div>Lat: {data?.latitude}</div>
                  <div>Lng: {data?.longitude}</div>
                </div> */}
                {/* {data?.mainOcc !== "ฉุกเฉิน" ? (<></>):(<></>)} */}
                {data?.mainOcc !== "ฉุกเฉิน" ? (
                  <>
                    <div className="text-base font-medium">
                      ระยะทาง: {!Number.isNaN(distance) ? distance : 0}
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4 justify-between mx-2 p-2 border border-[#504f4f] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] rounded-md overflow-hidden">
          {/*           <Link
            to={`/userchat/${item?.memberID}/${item?.id}`}
            className="text-sm font-medium flex justify-center flex-col items-center"
          >
            <IoChatboxEllipsesOutline fontSize={22} />
            Chat${item?.memberID}/${item?.id}
          </Link> */}
          {/*           <button
            onClick={gotoUserChat}
            className="text-sm font-medium flex justify-center flex-col items-center"
          >
            <IoChatboxEllipsesOutline fontSize={22} />
            Chat
          </button> */}
          {/*           <a
            href={`tel:${item?.phone}`}
            className="text-sm font-medium flex justify-center flex-col items-center"
          >
            <FiPhoneCall fontSize={20} />
            Call
          </a> */}
          {data?.phone ? (
            <>
              <a
                href={`tel:${data?.phone}`}
                className="text-sm font-medium flex justify-center flex-col"
              >
                <FiPhoneCall fontSize={20} />
                Call
              </a>
            </>
          ) : (
            <>
              <span className="text-sm font-medium flex justify-center flex-col">
                <FiPhoneCall fontSize={20} />
                Call
              </span>
            </>
          )}
          <button
            onClick={gotoUserContact}
            className="text-sm font-medium flex justify-center flex-col items-center"
          >
            <TiContacts fontSize={22} />
            Contact
          </button>
          {/*           <Link
            to={"/usercontact"}
            className="text-sm font-medium flex justify-center flex-col items-center"
          >
            <TiContacts fontSize={22} />
            Contact
          </Link> */}
          <button
            onClick={() => {}}
            className="text-sm text-slate-600 font-medium flex justify-center flex-col items-center"
          >
            <BsShare fontSize={18} />
            share
          </button>
          <Link
            to={`/providergallery/${data?.id}`}
            className="text-sm font-medium flex justify-center flex-col items-center"
          >
            <GrGallery fontSize={22} />
            Gallery
          </Link>
          {data?.mainOcc !== "ฉุกเฉิน" ? (
            <>
              <Link
                to={`/providerlocation/${data?.longitude}/${data?.latitude}/${data?.id}`}
                className="text-sm font-medium flex justify-center flex-col items-center"
              >
                <FaMapMarkerAlt fontSize={22} />
                Map
              </Link>
            </>
          ) : (
            <>
              <div className="text-sm font-medium text-slate-600  flex justify-center flex-col items-center">
                <FaMapMarkerAlt fontSize={22} />
                Map
              </div>
            </>
          )}
        </div>
        {socialData.length > 0 ? (
          <>
            <div className="flex flex-row gap-4 justify-between mx-2 mt-2 p-2 border border-[#504f4f] shadow-[0px_4px_14px_rgba(0,0,0,0.04)] rounded-md overflow-hidden">
              {socialData.map((item, idx) => (
                <SocialMediaCard item={item} key={`soc_${idx}`} />
              ))}
            </div>
          </>
        ) : null}
        <div>
          <div
            id="longdo-map"
            className={`w-[400px] h-[300px] text-black hidden`}
          ></div>
        </div>

        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default ProviderContactProfile;
