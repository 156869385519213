import React, { useState, useEffect, useRef, useContext } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  memberUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard, HeaderMap } from "../components";
import axios from "axios";
import { UserLocationContext } from "../context/UserLocationContext";
// import { longdo, map, LongdoMap } from "../longdo-map/LongdoMap";
// import { longdo } from './../longdo-map/LongdoMap';
/* import { longdo, map } from "../components/LongdoMap";
import LongdoMap from "../components/LongdoMap"; */
const ProviderLocation = () => {
  //:lon/:lat
  const { lon, lat, id } = useParams();
  // const location = useLocation();
  const navigate = useNavigate();
  // const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  // const [currentLocation, setCurrentLocation] = useState(null);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  // const { location } = useContext(UserLocationContext);
  const { location, setLocation } = useContext(UserLocationContext);
  const [pvData, setPvData] = useState({});

  const readProvider = (id) => {
    //readcompanybyid.php
    axios
      .get(`${memberUrl}readcompanybyid.php/${id}`)
      .then(function (response) {
        console.log("PV DATA: ", response.data);
        setPvData(response.data);
      });
  };
  // const { location, setLocation } = useContext(UserLocationContext);
  /*   let longdo;
  let map; */

  /*   const mapCallback = () => {
    longdo = window.longdo;
    map = new window.longdo.Map({
      placeholder: document.getElementById("longdo-map"),
      language: "en",
    });
  }; */

  /*   const initMap = () => {
    map.Layers.setBase(longdo.Layers.GRAY);
  };
 */
  const longdo_key = "6b75448d3c0808af052906060b770d1c";
  // const mapRef = useRef();
  //lon, lat
  /* lon: location?.longitude,
lat: location?.latitude, */
  var map;
  var marker;
  var longdo = window.longdo;
  const mapCallback = () => {
    console.log("mapCallback");
    // readProvider(id);
    // console.log("Location mapCallback: ", location);
    /*     marker = new window.longdo.Marker({
      lon: Number(lon),
      lat: Number(lat),
    }); */
    // { lon: 100.538316, lat: 13.764953 },
    // { title: pvData?.companyName, detail: pvData?.companyDetail }
    //provider
    marker = new window.longdo.Marker(
      { lon: Number(lon), lat: Number(lat) },
      { title: "Hello", detail: "I'm here" }
    );
    console.log("Company Name: ", pvData?.companyName);
    /*     marker = new window.longdo.Marker(
      { lon: 100.538316, lat: 13.764953 },
      { title: "Victory monument", detail: "I'm here" }
    ); */
    map = new window.longdo.Map({
      zoom: 12,
      /*       location: {
        lon: Number(lon),
        lat: Number(lat),
      }, */
      placeholder: document.getElementById("longdo-map"),
      lastView: false,
    });
    // map.Overlays.add(marker);

    map.Event.bind("ready", function () {
      let mylocation = map.location(); // Cross hair location
      console.log("Ready Location: ", mylocation);
      console.log("my lon", mylocation.lon);
      console.log("my lat", mylocation.lat);
      longdo.Server.route = "https://api.longdo.com/RouteServiceTruck";
      // longdo.Server.route = "https://api.longdo.com/RouteServiceTruck/geojson";
      // map.Route.placeholder(document.getElementById("result"));
      map.Route.add(marker);
      // map.Route.add({ lon: mylocation.lon, lat: mylocation.lat });
      map.Route.add({ lon: mylocation.lon, lat: mylocation.lat });
      map.Route.search();
      // map.location()
      // console.log("Map Ready");
    });
  };

  /*   const initMap = () => {language: "en",
    map.Layers.setBase(longdo.Layers.GRAY);
  };
 */
  /*   useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords);
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setCurrentLocation({ latitude, longitude });
        console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      });
    } else {
      console.log("Geolocation not supported");
    }
  }, [currentLocation]);  */
  useEffect(() => {
    const existingScript = document.getElementById("longdoMapScript");
    readProvider(id);
    /*     if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://api.longdo.com/map/?key=${longdo_key}`;
      script.id = "longdoMapScript";
      document.body.appendChild(script);

      script.onload = () => {
        mapCallback();
        console.log(map);
      };
    } */

    if (existingScript) mapCallback();
    // readProvider(id);
    /*     const script = document.createElement("script");

    script.src = `https://api.longdo.com/map3/?key=${longdo_key}`;
    script.async = true;

    document.body.appendChild(script);
    if (typeof window === "object") {
      // Check if document is finally loaded
      document.addEventListener("DOMContentLoaded", function () {
        // *** VANILLA JAVASCRIPT CODE FOR THE IMAGE GALLERIE ***
        console.log("Now");
      });
    }

    return () => {
      document.body.removeChild(script);
    }; */
    // if (existingScript && callback) callback();
    // initMap();
    // alert("Hello");
    // const handleLoad = () => {
    // Perform actions after the component has fully loaded
    // };
    /*     window.addEventListener("load", handleLoad);
    return () => {
      window.removeEventListener("load", handleLoad);
    };
    const divElement = mapRef.current;
    console.log(divElement); */
  }, []);
  // console.log("Location: ", location);
  console.log("MY LOCATION: ", location);
  return (
    <>
      <HeaderMap />
      {/* <div>{JSON.stringify(currentLocation)}</div> */}
      <div className="overflow-y-scroll">
        {/* -------  */}
        <div className="mt-[4rem]">
          <div
            className={`w-[${windowSize[0]}px] h-[${windowSize[1]}px] overflow-hidden mb-1`}
          >
            {/* <LongdoMap id="longdo-map" mapKey={longdo_key} callback={initMap} /> */}
            <div
              id="longdo-map"
              className={`w-[${windowSize[0]}px] h-[600px] text-black`}
            ></div>
            {/* <div>{JSON.stringify(location)}</div>   lon: location?.longitude,
lat: location?.latitude,  */}
            <div className="text-center py-4">
              {pvData?.companyName},{pvData?.companyDetail}
              <br />
              Longitude: {lon},<br /> Latitude: {lat}
            </div>
            {/*             <div className="text-center py-4">
              title: {pvData?.companyName}, detail: {pvData?.companyDetail}
            </div> */}
            {/* <LongdoMap id={"longdo-map"} mapKey={longdo_key} callback={initMap} /> */}
          </div>
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default ProviderLocation;
