import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgUnUrl,
  imgGalleryUrl,
} from "../constants/BaseUrl";

const GallerySlide = ({ item, width }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location; //w-[${width}px]
  return (
    <div className="mt-[1rem] cursor-pointer rounded-xl shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
      <div
        className={`w-[300px] h-[${width * 0.5}px] rounded-lg overflow-hidden`}
      >
        <img
          //   src={item.image}
          src={`${imgGalleryUrl}${item.photo}`}
          alt={item.thName}
          className={`w-[300px] h-[${width * 0.5}px] object-cover`}
        />
        {/*         <div className="absolute left-0 bottom-2 p-4 bg-[rgba(0,0,0,0.5)] text-center  w-full">
      <strong className="text-lg font-medium text-white">
        {item.thName}
      </strong>
    </div> */}
      </div>
      {/* <div>{width}</div> */}
    </div>
  );
};

export default GallerySlide;
