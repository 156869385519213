import React, { useState, useEffect, useContext } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  pvshopUrl,
  searchcompanyUrl,
  memberUrl,
  imgShopUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard, LogoCard } from "../components";
import axios from "axios";
import { FiPhoneCall } from "react-icons/fi";
import { BiLike } from "react-icons/bi";
import { FaRegCommentDots } from "react-icons/fa";
import { BsShare } from "react-icons/bs";
import { AiOutlineSend } from "react-icons/ai";
import { UserLocationContext } from "../context/UserLocationContext";
const ProviderCard = ({ item, width }) => {
  const uselocation = useLocation();
  const navigate = useNavigate();
  const { pathname } = uselocation;
  const [isLoading, setIsLoading] = useState(true);
  const [companyInfoData, setCompanyInfoData] = useState({});
  const [companyLogoData, setCompanyLogoData] = useState(null);
  const [logoData, setLogoData] = useState([]);
  const [likes, setLikes] = useState("0");
  const [urlLink, setUrlLink] = useState("");
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [distance, setDistance] = useState(null);
  const [pvLocation, setPvlocation] = useState({
    lon: item?.longitude,
    lat: item?.latitude,
  });
  const { location, setLocation } = useContext(UserLocationContext);
  function readCompanyDetail(id) {
    //export const pvuserUrl = `${url}users/`; //u
    axios
      .get(`${searchcompanyUrl}readcompanydetail.php/${id}/pvmycom`)
      .then(function (response) {
        setCompanyInfoData(response.data);
        // readCompanyLogo(response.data?.memberId);
      });
  }

  const readLogo = (id) => {
    //readlogomember.php
    //logoData, setLogoData
    //readlogoformember.php
    // export const memberUrl = `${url}member/`;
    axios
      .get(`${memberUrl}readlogoofmember.php/${id}`)
      .then(function (response) {
        console.log("LOGO: ", response.data);
        setLogoData(response.data);
      });
  };

  const formatDistance = (nm) => {
    if (nm >= 1000) {
      return Math.round(nm / 1000.0) + " กิโลเมตร";
    } else if (nm >= 100) {
      return Math.round(nm) + " เมตร";
    } else {
      return nm.toFixed(1) + " เมตร";
    }
  };

  var map;
  var marker;
  var marker2;
  var longdo = window.longdo;
  const mapCallback = () => {
    console.log("mapCallback123");

    // var currentLocation = map.location();
    // console.log("Location mapCallback: ", location);
    //{lon: 98.99211228493506, lat: 18.81642135851569}
    //273 ถ.ป่าตัน ต.ป่าตัน อ.เมืองเชียงใหม่ จ.เชียงใหม่ 50300
    //{lon: 98.99370767176151, lat: 18.8298221084872}
    // console.log("Longdo object: ", longdo);
    /*     {
      lon: location?.longitude,
      lat: location?.latitude,
    }, */
    /*       {
        lon: 100.64842293068324,
        lat: 13.787791382430747,
      }, */

    //13.787791382430747, 100.64842293068324
    /*     marker = new window.longdo.Marker(
      {
        lon: pvLocation?.lon,
        lat: pvLocation?.lat,
      },
      {
        title: "Marker 1",
        detail: "Drag me",
        draggable: true,
      }
    ); */

    map = new window.longdo.Map({
      zoom: 12,
      /*       location: {
        lon: pvLocation?.lon,
        lat: pvLocation?.lat,
      }, */
      placeholder: document.getElementById("longdo-map"),
      lastView: false,
    });
    map.Event.bind("ready", function () {
      //longitude,latitude
      // let mylocation = map.location(); // Cross hair location
      let marker1 = { lat: location?.latitude, lon: location?.longitude };
      let marker2 = { lat: item?.latitude, lon: item?.longitude };
      let mydistance = longdo.Util.distance([marker1, marker2]); //data
      setDistance(formatDistance(mydistance));
      /*       console.log(
        `Latitude2=>: ${locations?.latitude}, Longitude2=>: ${locations?.longitude}`
      ); */
      // console.log("Ready Location: ", mylocation);
      // map.location()
      /*       console.log("M1: ", marker1);
      console.log("M2: ", marker2);
      console.log(formatDistance(mydistance), mydistance); */
      // console.log(data?.latitude, data?.longitude);
    });
    /*     map.Event.bind("overlayClick", function (overlay) {
      console.log(overlay);
    }); */
    /*     map.Event.bind(longdo.EventName.OverlayClick, function (overlay) {
      console.log("overlay");
    }); */
    /*     location: {
      lon: location?.longitude,
      lat: location?.latitude,
    }, */

    /*     location: {
      lon: 100.64842293068324,
      lat: 13.787791382430747,
    }, */

    map.Event.bind(longdo.EventName.OverlayClick, function (overlay) {
      var mouseLocation = map.location(longdo.LocationMode.Pointer);
      // console.log("OverlayClick: ", mouseLocation);
      console.log(overlay);
    });
    //OverlayDrop
    map.Event.bind(longdo.EventName.OverlayDrop, function (overlay) {
      var mouseLocation = map.location(longdo.LocationMode.Pointer);
      // console.log("OverlayDrop: ", mouseLocation);
      // setSearchLocation(mouseLocation);
      /*       console.log("OverlayDrop: ", overlay);
      console.log("lon: ", mouseLocation.lon);
      console.log("lat: ", mouseLocation.lat); */
      // findOverlayDrop(mouseLocation.lon, mouseLocation.lat);
      //13.787791382430747, 100.64842293068324
      // findOverlayDrop(100.64842293068324, 13.787791382430747);
    });
    map.Event.bind(longdo.EventName.OverlayMove, function (overlay) {
      // var mouseLocation = map.location(longdo.LocationMode.Pointer);
      // console.log("Mouse locationxxx: ", mouseLocation);
      // setSearchLocation(mouseLocation);
      /*       if (mouseLocation) {
        readGeocoding();
      } */
    });
    // map.location(new window.longdo.LocationMode.Geolocation());
    // map.Overlays.add(marker);
    // map.Overlays.add(marker2);
  };

  useEffect(() => {
    const existingScript = document.getElementById("longdoMapScript");
    var user = localStorage.getItem("uid");
    if (existingScript) mapCallback();
    setUserId(user);
    readCompanyDetail(item?.id);
    readLogo(item?.memberID);
  }, []);

  const saveLikes = async () => {
    axios
      .post(`${searchcompanyUrl}addlikes.php`, {
        companyId: item?.id,
      })
      .then(function (response) {
        console.log(response.data);
        //miid, maid, miname///ProviderProfile
      });
  };
  //Comments
  const gotoComment = () => {
    const itemData = {
      comId: item?.id,
      type: 0,
      title: "Comment",
    };
    navigate("/comments", { state: itemData });
  };
  const gotoSendContact = () => {
    const itemData = {
      comId: item?.id,
      type: 1,
      title: "ติดต่อผู้ให้บริการ",
    };
    navigate("/sendcontact", { state: itemData });
  };
  /*   navigate('/Players', {
    userId: id,
  }); */
  console.log("ITEM: ", item);
  const saveContact = async () => {
    try {
      axios
        .get(
          `${searchcompanyUrl}checkcontact.php/${userId}/${item?.memberID}/${item?.id}`
        )
        .then(function (response) {
          if (response.data.count > 0) {
            console.log("Update preview: ");
            axios
              .post(`${searchcompanyUrl}updatepreview.php`, {
                userId: userId,
                memId: item?.memberID,
                companyId: item?.id,
              })
              .then(function (response) {
                // navigate("/ProviderContactProfile", { item: item });
                navigate("/ProviderContactProfile", {
                  state: {
                    item: item,
                  },
                  // replace: true,
                });
                // navigate("/ProviderContactProfile", {
                /*                   companyId: item?.id,
                  memId: item?.memberID, */
                // item: item,
                // uid: userId,
                // image: image,
                /*                   occupation: item?.minorOcc,
                  location,
                  status: "insert",
                  uLocation: "",
                  distance: "", */
                // });
              });
          } else {
            axios
              .post(`${searchcompanyUrl}addpreview.php`, {
                userId: userId,
                memId: item?.memberID,
                companyId: item?.id,
                minorOccId: item?.minorOccId,
                minorOccname: item?.minorOcc,
                latitude: "",
                longitude: "",
              })
              .then(function (response) {
                console.log(response.data);
                // navigate("/ProviderContactProfile", { item: item });
                navigate("/ProviderContactProfile", {
                  state: {
                    item: item,
                  },
                  // replace: true,
                });
                // navigate("/ProviderContactProfile", {
                /*                   companyId: item?.id,
                  memId: item?.memberID, */
                // item: item,
                // uid: userId,
                // image: image,
                /*                   occupation: item?.minorOcc,

                  location,
                  status: "update",
                  uLocation: "",
                  distance: "", */
                // });
              });
          }
        });
      console.log("Save");
    } catch (error) {
      console.log(error);
    }
  };
  // console.log("Co,pany: ", item);
  /*  console.log("Member Logo: ", logoData); */
  return (
    <>
      <div
        className={`w-[${width}px] h-[${width}px] rounded-lg border-b p-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
      >
        <div>
          <LogoCard item={item} />
        </div>
        {/*         <div>
          $UID:{userId}/$MEMID:{item?.memberID}/$COMID:{item?.id}
        </div>block  */}
        <div className="flex flex-row gap-4 py-2">
          <div className="w-[80px] h-[80px] bg-red-500 rounded-full overflow-hidden mb-1">
            {item?.companyPhoto !== null ? (
              <>
                <img
                  src={`${imgShopUrl}${item?.companyPhoto}`}
                  alt={item.name}
                  className="w-[80px] h-[80px] rounded-full object-cover"
                />
              </>
            ) : (
              <>
                <>
                  <img
                    src={require("../assets/images/person_color.png")}
                    alt={item.name}
                    className="w-[80px] h-[80px] rounded-full object-cover"
                  />
                </>
              </>
            )}
          </div>
          <div className="flex-1 w-full">
            {item?.province ? (
              <>
                <strong className="text-sm font-medium">
                  {item?.province}
                </strong>
                <br />
              </>
            ) : null}
            <strong className="block text-sm font-medium">
              {item?.mainOcc} {" | "}
              {item?.minorOcc}
            </strong>
            <div className="text-sm font-medium">
              {item?.companyName}{" "}
              <span className="">
                โทรศัพท์ {item?.phone ? item?.phone.slice(0, 7) + "XXX" : "-"}
              </span>
            </div>
            {/*             <div>
              <div>Lat: {item?.latitude}</div>
              <div>Lng: {item?.longitude}</div>
            </div> */}

            {item?.mainOcc !== "ฉุกเฉิน" ? (
              <>
                <div className="text-base font-medium">
                  ระยะทาง: {!Number.isNaN(distance) ? distance : 0}
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="text-sm text-right w-full my-2">
              {userId ? (
                <>
                  <button
                    onClick={() => saveContact()}
                    className="w-[100px] bg-sky-500 text-white py-1 rounded-sm"
                  >
                    ดูโปรไฟล์
                  </button>
                </>
              ) : (
                <>
                  <Link
                    to={"/signin"}
                    className="w-[150px] px-4 bg-sky-500 text-white py-1 rounded-sm"
                  >
                    เข้าระบบสมาชิก
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4 justify-between my-1">
          <button
            onClick={() => saveLikes(item?.id)}
            className="text-sm font-medium flex justify-center flex-col items-center"
          >
            <BiLike fontSize={22} />
            Like
          </button>
          {userId ? (
            <>
              <button
                onClick={gotoComment}
                className="text-sm font-medium flex justify-center flex-col items-center"
              >
                <FaRegCommentDots fontSize={20} />
                comment
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => {}}
                className="text-sm font-medium text-[#646464] flex justify-center flex-col items-center"
              >
                <FaRegCommentDots fontSize={20} color="#646464" />
                comment
              </button>
            </>
          )}

          <button
            onClick={() => {}}
            className="text-sm text-slate-600 font-medium flex justify-center flex-col items-center"
          >
            <BsShare fontSize={18} />
            share
          </button>
          {userId ? (
            <>
              <button
                onClick={gotoSendContact}
                className="text-sm font-medium flex justify-center flex-col items-center"
              >
                <AiOutlineSend fontSize={20} />
                send
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => {}}
                className="text-sm font-medium text-[#646464] flex justify-center flex-col items-center"
              >
                <AiOutlineSend fontSize={20} color="#646464" />
                send
              </button>
            </>
          )}
          {/* <a href="tel:2348101234567"></a> */}
          <a
            href={`tel:${item?.phone}`}
            className="text-sm font-medium flex justify-center flex-col"
          >
            <FiPhoneCall fontSize={20} />
            Call
          </a>
          {/*           <Link
            to={""}
            className="text-sm font-medium flex justify-center flex-col"
          >
            <FiPhoneCall fontSize={20} />
            Call
          </Link>  */}
        </div>
      </div>
      <div>
        <div
          id="longdo-map"
          className={`w-[400px] h-[300px] text-black hidden`}
        ></div>
      </div>
    </>
  );
};

export default ProviderCard;
