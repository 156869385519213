import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard, GallerySlide } from "../components";
import axios from "axios";

const ProviderGallery = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [gallerys, setGallerys] = useState([]);
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  function readGallery(id) {
    axios.get(`${pvuserUrl}readgallery.php/${id}`).then(function (response) {
      // console.log("Gallery==>: ", response.data);
      setGallerys(response.data);
    });
  }
  useEffect(() => {
    readGallery(id);
  }, []);
  /*   console.log("ID: ", id);
  console.log("Gallery: ", gallerys); */
  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}

        <div className="mt-[6rem]">
          <div className="no-scrollbar flex gap-3 overflow-x-auto">
            {Object.keys(gallerys).length !== 0 ? (
              <>
                {gallerys.map((item, idx) => (
                  <GallerySlide
                    key={`gallery_${idx}`}
                    item={item}
                    width={windowSize[0]}
                  />
                ))}
              </>
            ) : (
              <>
                <div
                  className={`w-[${windowSize[0]}px] h-[${
                    windowSize[1] * 0.5
                  }px] rounded-lg overflow-hidden relative`}
                >
                  <img
                    src={require("../assets/images/no-8.png")}
                    alt={""}
                    className={`w-[${windowSize[0]}px] h-[${
                      windowSize[1] * 0.5
                    }px] object-cover`}
                  />
                  <div className="absolute left-0 bottom-1 p-4 bg-[rgba(0,0,0,0.5)] text-center  w-full">
                    <strong className="text-sm text-white">No Gallery</strong>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default ProviderGallery;
