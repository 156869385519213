import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  pvshopUrl,
  searchcompanyUrl,
  memberUrl,
  imgShopUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard } from "../components";
import axios from "axios";
import { FiPhoneCall } from "react-icons/fi";
import { BiLike } from "react-icons/bi";
import { FaRegCommentDots } from "react-icons/fa";
import { BsShare } from "react-icons/bs";
import { AiOutlineSend } from "react-icons/ai";
import { partnercompany } from "./../constants/BaseUrl";
const ProviderEmergencyCard = ({ item, width }) => {
  //   const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(true);
  const [companyInfoData, setCompanyInfoData] = useState({});
  const [companyLogoData, setCompanyLogoData] = useState(null);
  const [logoData, setLogoData] = useState([]);
  const [likes, setLikes] = useState("0");
  const [urlLink, setUrlLink] = useState("");
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState("");
  function readCompanyDetail(id) {
    //export const pvuserUrl = `${url}users/`; //u
    axios
      .get(`${searchcompanyUrl}readcompanydetail.php/${id}/pvmycom`)
      .then(function (response) {
        setCompanyInfoData(response.data);
        // readCompanyLogo(response.data?.memberId);
      });
  }

  const readLogo = (id) => {
    //readlogomember.php
    //logoData, setLogoData
    //readlogoformember.php
    axios
      .get(`${memberUrl}readlogoofmember.php/${id}`)
      .then(function (response) {
        setLogoData(response.data);
      });
  };

  useEffect(() => {
    var user = localStorage.getItem("id");
    setUserId(user);
    readCompanyDetail(item?.id);
    readLogo(item?.id);
  }, []);

  const saveLikes = async () => {
    axios
      .post(`${searchcompanyUrl}addlikes.php`, {
        companyId: item?.id,
      })
      .then(function (response) {
        console.log(response.data);
        //miid, maid, miname///ProviderProfile
      });
  };

  /*   navigate('/Players', {
    userId: id,
  }); */

  const saveContact = async () => {
    try {
      if (userId === "") {
        // console.log("123");
        navigate("/signin", {
          userId: userId,
        });
      }
      axios
        .get(
          `${searchcompanyUrl}checkcontact.php/${userId}/${item?.memberID}/${item?.id}`
        )
        .then(function (response) {
          if (response.data.count > 0) {
            console.log("Update preview: ");
            axios
              .post(`${searchcompanyUrl}updatepreview.php`, {
                userId: userId,
                memId: item?.memberID,
                companyId: item?.id,
              })
              .then(function (response) {
                // navigate("/ProviderContactProfile", { item: item });
                navigate("/ProviderContactProfile", {
                  state: {
                    item: item,
                  },
                });
                // navigate("/ProviderContactProfile", {
                /*                   companyId: item?.id,
                  memId: item?.memberID, */
                // item: item,
                // uid: userId,
                // image: image,
                /*                   occupation: item?.minorOcc,
                  location,
                  status: "insert",
                  uLocation: "",
                  distance: "", */
                // });
              });
          } else {
            axios
              .post(`${searchcompanyUrl}addpreview.php`, {
                userId: userId,
                memId: item?.memberID,
                companyId: item?.id,
                minorOccId: item?.minorOccId,
                minorOccname: item?.minorOcc,
                latitude: "",
                longitude: "",
              })
              .then(function (response) {
                console.log(response.data);
                // navigate("/ProviderContactProfile", { item: item });
                navigate("/ProviderContactProfile", {
                  state: {
                    item: item,
                  },
                });
                // navigate("/ProviderContactProfile", {
                /*                   companyId: item?.id,
                  memId: item?.memberID, */
                // item: item,
                // uid: userId,
                // image: image,
                /*                   occupation: item?.minorOcc,

                  location,
                  status: "update",
                  uLocation: "",
                  distance: "", */
                // });
              });
          }
        });
      console.log("Save");
    } catch (error) {
      console.log(error);
    }
  };

  const gotoComment = () => {
    const itemData = {
      comId: item?.id,
      type: 0,
      title: "Comment",
    };
    navigate("/comments", { state: itemData });
  };
  const gotoSendContact = () => {
    const itemData = {
      comId: item?.id,
      type: 1,
      title: "ติดต่อผู้ให้บริการ",
    };
    navigate("/sendcontact", { state: itemData });
  };
  //{`w-[${width}px] h-[${width}px] rounded-lg overflow-hidden mb-1`}
  //"rounded-lg border border-[#EDEDED] p-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1"
  return (
    <>
      <div
        className={`w-[${width}px] h-[${width}px] rounded-lg border-b p-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
      >
        <div className="flex flex-row gap-4 py-3 mb-2">
          <div className="w-[80px] h-[80px] bg-red-500 rounded-full overflow-hidden mb-1">
            <img
              src={`${imgShopUrl}${item?.companyPhoto}`}
              alt={item.name}
              className="w-[80px] h-[80px] rounded-full object-cover"
            />
          </div>
          <div className="flex-1 w-full">
            {item?.province ? (
              <>
                <strong className="text-sm font-medium">
                  {item?.province}
                </strong>
                <br />
              </>
            ) : null}

            <strong className="text-sm font-medium">{item?.companyName}</strong>
            <p className="text-sm font-medium">{item?.companyDetail}</p>
            <strong className="flex block text-lg gap-1 items-center">
              <FiPhoneCall fontSize={18} />
              {item?.phone ? item?.phone : "ไม่ระบุเบอร์โทร"}
            </strong>
            <div className="text-sm text-right w-full">
              <button
                onClick={() => saveContact()}
                className="w-[100px] bg-sky-500 text-white py-1 rounded-sm"
              >
                ดูโปรไฟล์
              </button>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-4 justify-between my-1">
          <button
            onClick={() => saveLikes(item?.id)}
            className="text-sm font-medium flex justify-center flex-col items-center"
          >
            <BiLike fontSize={22} />
            Like
          </button>
          <button
            // onClick={() => saveLikes(item?.id)}
            onClick={gotoComment}
            className="text-sm font-medium flex justify-center flex-col items-center"
          >
            <FaRegCommentDots fontSize={20} />
            comment
          </button>
          <button
            onClick={() => {}}
            className="text-sm font-medium text-slate-600  flex justify-center flex-col items-center"
          >
            <BsShare fontSize={18} />
            share
          </button>
          <button
            // onClick={() => saveLikes(item?.id)}
            onClick={gotoSendContact}
            className="text-sm font-medium flex justify-center flex-col items-center"
          >
            <AiOutlineSend fontSize={20} />
            Contact
          </button>
          {item?.phone ? (
            <>
              <a
                href={`tel:${item?.phone}`}
                className="text-sm font-medium flex justify-center flex-col"
              >
                <FiPhoneCall fontSize={20} />
                Call
              </a>
            </>
          ) : (
            <>
              <span className="text-sm font-medium flex justify-center flex-col">
                <FiPhoneCall fontSize={20} />
                Call
              </span>
            </>
          )}

          {/*           <Link
            to={""}
            className="text-sm font-medium flex justify-center flex-col"
          >
            <FiPhoneCall fontSize={20} />
            Call
          </Link> */}
        </div>
      </div>
    </>
  );
};

export default ProviderEmergencyCard;
