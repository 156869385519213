import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgGovUrl,
} from "../constants/BaseUrl";

const AdvertProvice = ({ item, width }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location; //w-[70px] items-center
  let h = width * 0.5;
  // console.log("WIDTH: ", width, " HEIGHT: ", h);

  return (
    <div className="mt-[1rem] cursor-pointer rounded-xl shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
      <a href={item?.url} target="_blank" className="">
        <div className={`w-[${width}px] h-[${h}px] rounded-lg`}>
          <img
            //   src={item.image} h-[${width * 0.5}px]{`w-full object-cover`}  overflow-hidden
            src={`${imgGovUrl}${item.photo}`}
            alt={item.thName}
            className={`w-[${width}px] h-[${h}px] rounded-lg object-cover`}
          />
          {/*         <div className="absolute left-0 bottom-2 p-4 bg-[rgba(0,0,0,0.5)] text-center  w-full">
          <strong className="text-lg font-medium text-white">
            {item.thName}
          </strong>
        </div> */}
        </div>
      </a>
      {/* <div>{width}</div> */}
    </div>
  );
};

export default AdvertProvice;
