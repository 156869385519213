import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgUserUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard } from "../components";
import axios from "axios";
import { IoImagesSharp } from "react-icons/io5";
import Swal from "sweetalert2";

const DeleteAccount = () => {
  // const { id,  } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [userAccount, setUserAccount] = useState({});
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [auth, setAuth] = useState(null);
  const [userId, setUserId] = useState("");
  const readAccount = (id) => {
    axios.get(`${pvuserUrl}readuser.php/${id}/pvacc`).then(function (response) {
      // console.log(response.data);
      setUserAccount(response.data);
      setFirstname(response.data.firstname);
      setFileName(response.data.picture);
      setLastname(response.data.lastname);
      setPhone(response.data.phone);
    });
  };

  useEffect(() => {
    var id = localStorage.getItem("uid");
    var user = localStorage.getItem("ufullname");
    /*     if (user === null) {
      navigate("/signin");
    } */
    console.log("user: ", user);
    setAuth(user);
    setUserId(id);
    readAccount(id);
  }, []);

  //deletedate
  const alertDelete = async (data) => {
    try {
      axios
        .post(`${pvuserUrl}deleteaccount.php`, {
          id: userId,
          isDelete: "0",
        })
        .then(function (response) {
          console.log(response.data);
          readAccount(userId);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          });
          // Alert.alert('แจ้งเตือน', 'บันทึกข้อมูลเรียบร้อยแล้ว');
          // navigation.navigate('signin');
          // navigation.dispatch(StackActions.replace('signin'));
        });
    } catch (err) {
      // Alert.alert('แจ้งเตือน', err.message);
      console.log(err.message);
    }
  };
  const alertCancle = async (data) => {
    try {
      axios
        .post(`${pvuserUrl}deleteaccount.php`, {
          id: userId,
          isDelete: "1",
        })
        .then(function (response) {
          // console.log(response.data);
          readAccount(userId);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "บันทึกข้อมูลเรียบร้อยแล้ว",
            showConfirmButton: false,
            timer: 1500,
          });
          // Alert.alert('แจ้งเตือน', 'บันทึกข้อมูลเรียบร้อยแล้ว');
          // navigation.navigate('signin');
          // navigation.dispatch(StackActions.replace('signin'));
        });
    } catch (err) {
      // Alert.alert('แจ้งเตือน', err.message);
      console.log(err.message);
    }
  };
  // console.log("Acc: ", userAccount);
  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* ------- windowSize ${windowSize[1]}*/}

        <div className="mt-[6rem]">
          <div
            className={`w-[${windowSize[0]}px] h-[500px] rounded-lg border border-[#504f4f] p-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden`}
          >
            {/* ----------  */}
            <div className="flex flex-row gap-4 py-2">
              <div className="w-[80px] h-[80px] rounded-full overflow-hidden mb-1">
                {userAccount?.picture !== null ? (
                  <>
                    <img
                      src={`${imgUserUrl}${userAccount?.picture}`}
                      alt={userAccount?.picture}
                      className="w-[80px] h-[80px] rounded-full object-cover"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={require("../assets/images/person_color.png")}
                      alt={userAccount?.picture}
                      className="w-[80px] h-[80px] rounded-full object-cover"
                    />
                  </>
                )}
              </div>
              <div className="flex-1 w-full">
                {/*             {item?.province ? (
            <>
              <strong className="text-sm font-medium">
                {item?.province}
              </strong>
              <br />
            </>
          ) : null} */}
                <strong className="block text-sm font-medium">
                  {userAccount?.firstname !== ""
                    ? `${userAccount?.firstname} ${userAccount?.lastname}`
                    : "ไม่ระบุชื่อสกุล"}
                </strong>
                <strong className="text-sm font-medium">
                  {" "}
                  อีเมล์: {userAccount?.email}
                </strong>
                <span className="block text-xs">
                  โทรศัพท์: {userAccount?.phone}
                </span>
                {/*                 <div className="text-sm text-right w-full">
                <button
                  onClick={() => {}}
                  className="w-[100px] bg-sky-500 text-white py-1 rounded-sm"
                >
                  แก้ไขโปรไฟล์
                </button>
              </div> */}
              </div>
            </div>
            {userAccount?.isDelete !== "1" ? (
              <div>
                <strong className=" font-normal text-red-500">
                  ***ระบบจะทำการลบข้อมูลภายใน 24 ชม.(กดปุ่มยกเลิกถ้าต้องการ)***
                </strong>
              </div>
            ) : (
              <strong>-</strong>
            )}
            <div className="flex flex-row gap-4 justify-end mb-2">
              {userAccount?.isDelete !== "1" ? (
                <>
                  <button
                    onClick={alertCancle}
                    className="w-[120px] bg-sky-500 text-white py-1 rounded-sm"
                  >
                    ยกเลิกลบบัญชี
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={alertDelete}
                    className="w-[120px] bg-red-500 text-white py-1 rounded-sm"
                  >
                    ลบบัญชี
                  </button>
                </>
              )}
              {/*               <Link
                to={"/editprofile"}
                className="w-[100px] text-center bg-sky-500 text-white py-1 rounded-sm"
              >
                แก้ไขโปรไฟล์
              </Link> */}
              {/*               <button
              onClick={() => {}}
              className="w-[100px] bg-sky-500 text-white py-1 rounded-sm"
            >
              แก้ไขโปรไฟล์
            </button> */}
            </div>
            {/* ----------  */}
          </div>
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default DeleteAccount;
