// const url = 'http://192.168.1.114:8080/mobileApi/';
// const url = 'http://192.168.1.112/mobileApi/';
// const url = "http://localhost/providerApi/";
//``
//บันทึกข้อมูล 192.168.1.110
//person_color
//person_color 18.789833,98.9707799
//192.168.1.120,192.168.1.120,192.168.1.126
//192.168.1.117,192.168.1.120,192.168.1.120
//192.168.1.109/192.168.1.105,192.168.1.126
//192.168.1.110/192.168.1.111/192.168.1.101
//172.20.10.2,192.168.1.120
// const url = 'http://172.20.10.2/providerApi/';
//privacy-policy / 192.168.1.137
//192.168.1.117/192.168.1.117/192.168.1.132/192.168.1.104
//const url = 'http://192.168.1.117:8080/mobileApi/';//192.168.1.137
// const url = 'http://192.168.1.130:8080/mobileApi/';
// const url = 'https://dashboardgiftlikes.com/mobileApi/';
//192.168.1.112
//const url = 'http://192.168.1.122:8080/appApi/'; // 192.168.1.129,192.168.1.129,192.168.1.129
//const url = 'http://192.168.1.121:8080/mobileApi/';
// const url = 'https://jdtextileandconsolidator.me/appApi/';
const url = "https://giftlikesinfo.com/appApi/";
// const url = "http://192.168.1.108:8080/appApi/";
// const url = 'http://192.168.1.106:8080/appApi/';
//const url = "http://192.168.1.105:8080/appApi/"; //192.168.1.106
//----------192.168.1.105
//Channel ID:2002632700
//Channel secret:5f6fdbf2ceac9ff2062361730e8a635e
//LIFF ID:2002632700-wPzMX491
//863ef080969ee2cac726d71e6dbe84dd
export const apiUrl = `${url}api/`;
export const settingsUrl = `${url}settings/`;
export const providerUrl = `${url}provider/`;
export const loginUrl = `${url}provider/account/`;
export const shopUrl = `${url}provider/company/`;
export const searchcompanyUrl = `${url}searchcompany/`;
export const pvshopUrl = `${url}pvshop/`;
export const pvuserUrl = `${url}users/`; //u
export const pvloginUrl = `${url}pvlogin/`; //u
export const memberUrl = `${url}member/`;

export const officesPTUrl = `${url}offices/policyterm/`; //officesPTUrl
export const officesFaqUrl = `${url}offices/faq/`;
//imgOfficeUrl
export const imgOfficeUrl = `${url}assets/office/`;
export const logoUrl = `${url}logo/`;
export const helpUrl = `${url}help/`;
export const proviceUrl = `${url}proviceapp/`;
export const govUrl = `${url}adverts/gov/`;
export const pvUrl = `${url}adverts/provider/`;
export const uUrl = `${url}adverts/unit/`;
export const imgGovUrl = `${url}assets/advert/gov/`;
export const imgProvUrl = `${url}assets/advert/provider/`;
export const imgUnUrl = `${url}assets/advert/unit/`;
export const bankUrl = `${settingsUrl}bank/`;

export const mPackageUrl = `${url}package/major/`;
export const sPackageUrl = `${url}package/supplement/`;
export const buyPackageUrl = `${url}buypackage/`;

export const imgPackageUrl = `${url}assets/package/`;
export const imgLogoUrl = `${url}assets/logo/`;
export const imgHelpUrl = `${url}assets/help/`;
//gf_web_api.comments
//imgDocUrl
/* export const searchcompanyUrl = `${url}searchcompany/`;
export const pvshopUrl = `${url}pvshop/`;
export const pvprofileUrl = `${url}pvprofile/`;
export const pvuserUrl = `${url}pvuser/`;
export const proviceUrl = `${url}proviceapp/`; */
//Image
export const imgShopUrl = `${url}assets/shop/`;
export const imgGalleryUrl = `${url}assets/gallery/`;
export const imgPvUrl = `${url}assets/pv_avatar/`;
export const imgUserUrl = `${url}assets/user_avatar/`;
export const imgProviceUrl = `${url}assets/locations/`;
//occupation\minor
//\occupation\main
export const imgOccUrl = `${url}assets/occupation/main/`;
export const imgMinorUrl = `${url}assets/occupation/minor/`;
export const imgDocUrl = `${url}assets/document_contact/`;
export const imgChatUrl = `${url}assets/chat/`;

/* export const imgOccUrl = `${url}assets/occupation/main/`;
export const imgMinorUrl = `${url}assets/occupation/minor/`;
export const imgDocUrl = `${url}assets/document_contact/`;
export const imgChatUrl = `${url}assets/chat/`; */

/* export const imgOccUrl = `${url}assets/occupation/main/`;
export const imgMinorUrl = `${url}assets/occupation/minor/`;
export const imgDocUrl = `${url}assets/document_contact/`;
export const imgChatUrl = `${url}assets/chat/`; */

//------------
/* export const pvloginUrl = `${url}pvlogin/`;
export const searchcompanyUrl = `${url}searchcompany/`;
export const pvshopUrl = `${url}pvshop/`;
export const pvprofileUrl = `${url}pvprofile/`;
export const pvuserUrl = `${url}pvuser/`;
export const proviceUrl = `${url}proviceapp/`; */
// export const proviceUrl = `${url}advertapp/`;
/* export const advertUrl = `${url}advert/`;
export const imgAdUrl = `${url}images/advert/`; */
// export const pvuserUrl = `${url}pvuser/`;
/*Image link*/
//import { advertUrl, imgAdUrl } from "../utils/baseUrl";
//imgPvUrl
/* export const imgShopUrl = `${url}images/shop/`;
export const imgPvUrl = `${url}images/user_avatar/`;
export const imgOccUrl = `${url}images/occphoto/`;
export const imgMinorUrl = `${url}images/minorphoto/`;
export const imgGalleryUrl = `${url}images/gallery/`;
export const imgSlipUrl = `${url}images/slip/`;
export const imgAdvUrl = `${url}images/advert/`;
export const imgLogoUrl = `${url}images/companylogo/`;
export const imgProviceUrl = `${url}images/proviceapp/`;
export const imgDocUrl = `${url}images/document_contact/`; */
//imgMinorUrl
// export const imgProviceUrl = `${url}images/proviceapp/`;
// const url = 'http://192.168.1.117/mobileApi/';
// const url = "http://localhost/providerApi/";
//``
//192.168.1.109/192.168.1.105
//192.168.1.110/192.168.1.111/192.168.1.101
//172.20.10.2
// const url = 'http://172.20.10.2/providerApi/';
// const url = 'https://dashboardgiftlikes.com/mobileApi/';
/* export const pvloginUrl = `${url}pvlogin/`;
export const pvshopUrl = `${url}pvshop/`;
export const pvprofileUrl = `${url}pvprofile/`;
export const pvuserUrl = `${url}pvuser/ */
// `;
// export const pvuserUrl = `${url}pvuser/`;
/*Image link*/
/* export const imgShopUrl = `${url}images/shop/`;
export const imgPvUrl = `${url}images/user_avatar/`;
export const imgOccUrl = `${url}images/occphoto/`;
export const imgMinorUrl = `${url}images/minorphoto/`;
export const imgGalleryUrl = `${url}images/gallery/`;
export const imgSlipUrl = `${url}images/slip/`; */
/* export const partneraccount = `${url}partneraccount/`;
export const partnercompany = `${url}partnercompanyinfo/`;
export const partnerinfo = `${url}partnerinfo/`;
export const distributorprofile = `${url}distributorprofile/`; */
//partnerinfo

/* export const disaccount = `${url}distributeraccount/`;
export const memberaccount = `${url}memberaccount/`;
export const gfincome = `${url}gfincome/`;

export const partnerincome = `${url}partnerincome/`;
export const distributeincome = `${url}distributeincome/`;
export const salepage = `${url}salepage/`;
export const dissalepage = `${url}dissalepage/`; */
// export const pnsalepage = `${url}pnsalepage/`;

/* export const bankUrl = `${url}bank/`;
export const authUrl = `${url}gflogin/`;
export const pnbankUrl = `${url}partnerbookbank/`;
export const disbankUrl = `${url}distributebank/`;

export const pnAuthUrl = `${url}pnlogin/`;
export const disAuthUrl = `${url}dislogin/`;
export const memberAuthUrl = `${url}providers/`;
export const userUrl = `${url}pvuser/`; */
// export const registerUrl = "http://localhost/providerApi/gflogin/";
//mainoccupation
/* export const mainOccUrl = `${url}mainoccupation/`;
export const minorOccUrl = `${url}minoroccupation/`;
export const imgOccUrl = `${url}images/occphoto/`;
export const imgPnUrl = `${url}images/pphoto/`;
export const imgPvUrl = `${url}images/user_avatar/`;
export const imgGalleryUrl = `${url}images/gallery/`;
export const imgDisUrl = `${url}images/dphoto/`;
export const imgMinorUrl = `${url}images/minorphoto/`;
export const imgSalepageUrl = `${url}images/salepage/`;
export const imgPnSalepageUrl = `${url}images/pnsalepage/`;
export const imgDisSalepageUrl = `${url}images/dissalepage/`;
export const imgProviceUrl = `${url}images/proviceapp/`;
export const imgAvUrl = `${url}images/advertapp/`;
export const packageUrl = `${url}package/`;
export const salepageUrl = `${url}salepage/`;
export const proviceappUrl = `${url}proviceapp/`;
export const avappUrl = `${url}advertapp/`; */
