import React, { useState, useEffect } from "react";

import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";

const PriceQuoteCard = ({ data, width }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const gotoPricequoteDetail = () => {
    const itemData = {
      pqID: data.id,
      pqData: data,
    };
    //contactId, comId, uid, memId
    navigate("/pricequoteContactDetail", { state: itemData });
  };
  return (
    <div
      className={`w-[${width[0]}px]  rounded-sm border border-[#504f4f] p-2 shadow-[0px_4px_14px_rgba(0,0,0,0.04)] overflow-hidden mb-1`}
    >
      <div>
        <div>
          <p> {data.created_at}</p>
          <p>
            {data.id}# {data.jobname}
          </p>
          <div>{data.total} บาท</div>
        </div>
      </div>
      <div className="flex justify-end">
        <Link
          to={`/pricequotecontactdetail/${data?.id}`}
          className="w-[150px] text-center bg-indigo-500 text-white py-1 rounded-sm"
        >
          <strong className="text-sm font-medium mt-1">
            {" "}
            รายละเอียดใบเสนอราคา
          </strong>
        </Link>
        {/*         <button
          onClick={gotoPricequoteDetail}
          className="w-[150px] text-center bg-indigo-500 text-white py-1 rounded-sm"
        >
          <strong className="text-sm font-medium mt-1">
            รายละเอียดใบเสนอราคา {data.id}
          </strong>
        </button> */}
      </div>
    </div>
  );
};

export default PriceQuoteCard;
