import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgMinorUrl,
  imgLogoUrl,
} from "../constants/BaseUrl";

const LogoCard = ({ item }) => {
  return (
    <div className="flex justify-end gap-4 items-center">
      <div>
        {item?.logo3 !== null ? (
          <>
            <a href={item?.url3} target="_blank">
              <img
                src={`${imgLogoUrl}${item.logo3}`}
                alt={""}
                className={`w-[35px] h-[35px] object-cover`}
              />
            </a>
          </>
        ) : null}
      </div>
      <div>
        {item?.logo4 !== null ? (
          <>
            <a href={item?.url4} target="_blank">
              <img
                src={`${imgLogoUrl}${item.logo4}`}
                alt={""}
                className={`w-[35px] h-[35px] object-cover`}
              />
            </a>
          </>
        ) : null}
      </div>
      <div>
        {item?.logo1 !== null ? (
          <>
            <a href={item?.url1} target="_blank">
              <img
                src={`${imgLogoUrl}${item.logo1}`}
                alt={""}
                className={`w-[35px] h-[35px] object-cover`}
              />
            </a>
          </>
        ) : (
          <div className={`w-[35px] h-[35px] text-black`}>x</div>
        )}
      </div>
      <div>
        {item?.logo2 !== null ? (
          <>
            <a href={item?.url2} target="_blank">
              <img
                src={`${imgLogoUrl}${item.logo2}`}
                alt={""}
                className={`w-[35px] h-[35px] object-cover`}
              />
            </a>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default LogoCard;
