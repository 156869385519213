import { useEffect, useRef, useState, useContext } from "react";
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import DefaultLayout from "./layout/DefaultLayout";
import "./App.css";

import {
  HomeDashboard,
  HomeServices,
  HomeSettings,
  HomeContact,
  Signin,
  Signup,
  Policy,
  MinorOccupation,
  ProviderEmergencyList,
  ProviderList,
  ProviderContactProfile,
  ProviderLocation,
  ProviderGallery,
  UserContact,
  UserChat,
  FullImage,
  Ratings,
  DocContacts,
  SendIdCard,
  PricequoteContact,
  PricequoteContactDetail,
  ProviceOccupation,
  NearOccupation,
  UserProfile,
  DeleteAccount,
  VIPPackage,
  EditProfile,
  Comments,
  SendContact,
  HelpCenter,
  RegisterMember,
  CallCenter,
  TermOfService,
  PrivacyPolicy,
  PricequoteContactList,
  SigninLine,
  SearchOccupation,
} from "./screens";
// 9820e2a8e06a7cf53d8521d1517578dd
import { UserLocationContext } from "./context/UserLocationContext";
const Layout = () => {
  return (
    <DefaultLayout>
      <Outlet />
    </DefaultLayout>
  );
};
const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <HomeDashboard />,
      },
      {
        path: "/homeservices",
        element: <HomeServices />,
      },
      {
        path: "/homesettings",
        element: <HomeSettings />,
      },

      {
        path: "/homecontact",
        element: <HomeContact />,
      },
      {
        path: "/minoroccupation/:id/:emergency",
        element: <MinorOccupation />,
      },
      {
        path: "/providerlist/:id",
        element: <ProviderList />, //  ,
      },
      {
        path: "/provideremergencyList/:id",
        element: <ProviderEmergencyList />,
      },
      {
        path: "/ProviderContactProfile",
        element: <ProviderContactProfile />,
      },
      {
        path: "/proviceoccupation", ///:item
        element: <ProviceOccupation />,
      },
      {
        path: "/nearoccupation", ///:item
        element: <NearOccupation />,
      },
      {
        path: "/providerlocation/:lon/:lat/:id",
        element: <ProviderLocation />,
      },
      {
        path: "/searchoccupation",
        element: <SearchOccupation />,
      },
      {
        path: "/providergallery/:id",
        element: <ProviderGallery />,
      },
      {
        path: "/fullimage/:id",
        element: <FullImage />,
      },
      {
        path: "/usercontact",
        element: <UserContact />,
      },
      {
        path: "/userchat/:memId/:comId",
        element: <UserChat />,
      },
      {
        path: "/comments",
        element: <Comments />,
      },
      {
        path: "/sendcontact",
        element: <SendContact />,
      },

      {
        path: "/ratings/:memId/:comId",
        element: <Ratings />,
      },
      {
        path: "/doccontacts",
        element: <DocContacts />,
      },
      {
        path: "/sendidcard",
        element: <SendIdCard />,
      },
      {
        path: "/pricequotecontact",
        element: <PricequoteContact />,
      },
      {
        path: "/pricequotecontactdetail/:id",
        element: <PricequoteContactDetail />,
      },
      {
        path: "/pricequoteContactList/:id",
        element: <PricequoteContactList />,
      },
      {
        path: "/userprofile",
        element: <UserProfile />,
      },
      {
        path: "/editprofile",
        element: <EditProfile />,
      },
      {
        path: "/deleteaccount",
        element: <DeleteAccount />,
      },
      {
        path: "/VIPPackage",
        element: <VIPPackage />,
      },
      {
        path: "/helpCenter",
        element: <HelpCenter />,
      },
      {
        path: "/registerMember",
        element: <RegisterMember />,
      },
      {
        path: "/callCenter",
        element: <CallCenter />,
      },
      {
        path: "/termOfService",
        element: <TermOfService />,
      },
      {
        path: "/privacyPolicy",
        element: <PrivacyPolicy />,
      },
      /* 
        HelpCenter,
  RegisterMember,
  CallCenter,
  TermOfService,
  PrivacyPolicy,
        UserChat,
  FullImage,
  Ratings,
  DocContacts,
  SendIdCard,
  PricequoteContact,
  PricequoteContactDetail,
       ProviderGallery,
  UserContact, 
      {
        path: "/provideremergencyList/ProviderContactProfile/:item/:uid",
        element: <ProviderContactProfile />,
      }, */
    ],
  },
  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/signinLine",
    element: <SigninLine />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/policy",
    element: <Policy />,
  },
]);
function App() {
  const longdo_key = "6b75448d3c0808af052906060b770d1c";
  // const myRef = useRef();
  const [location, setLocation] = useState(null);
  // const [currentLocation, setCurrentLocation] = useState(null);
  // const { location, setLocation } = useContext(UserLocationContext);
  useEffect(() => {
    const existingScript = document.getElementById("longdoMapScript");

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://api.longdo.com/map/?key=${longdo_key}`;
      script.id = "longdoMapScript";
      document.body.appendChild(script);
      // var longdo = window;

      script.onload = () => {
        // mapCallback();
        // console.log(map);
        /*         this.mapCallback();
        if (callback) callback(); */
      };
    }

    // if (existingScript) mapCallback();
  }, []);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords);
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setLocation({ latitude: latitude, longitude: longitude });
        // console.log(`Latitude=>: ${latitude}, Longitude=>: ${longitude}`);
      });
    } else {
      console.log("Geolocation not supported");
    }
  }, []); //

  // var map;
  /*   useEffect(() => {
    const script = document.createElement("script");

    script.src = `https://api.longdo.com/map3/?key=${longdo_key}`;
    script.async = true;

    document.body.appendChild(script);
    var map = new longdo.map();

    return () => {
      document.body.removeChild(script);
    };
  }, []); */
  return (
    <UserLocationContext.Provider value={{ location, setLocation }}>
      <RouterProvider router={router} />
    </UserLocationContext.Provider>
  );
}

export default App;
