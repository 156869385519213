import React, { useState, useEffect, useCallback } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard, HeaderHome } from "../components";
import axios from "axios";
import Swal from "sweetalert2";
import LineLogo from "../assets/icons/line-logo.png";
import GoogleLogo from "../assets/icons/google.png";
import { LoginSocialGoogle, LoginSocialFacebook } from "reactjs-social-login";
import {
  FacebookLoginButton,
  GoogleLoginButton,
} from "react-social-login-buttons";
import liff from "@line/liff";
const Signin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  });
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);
  const [profileFb, setProfileFb] = useState(null);
  const [socialData, setSocialData] = useState(null);

  const [user, setUser] = useState({ email: "", password: "" });
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setUser((values) => ({ ...values, [name]: value }));
  };
  //client_id
  /*   const clientID =
    "18394294506-luj6vo6p27l0fs675csatq2h5pu5boec.apps.googleusercontent.com"; */
  const clientID =
    "771221435459-aeujotlpepht0h2721ekn723isatutc5.apps.googleusercontent.com";
  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);

  const options = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
  };

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  function readEmail(email) {
    axios
      .get(`${pvuserUrl}reademailuser.php/${email}`)
      .then(function (response) {
        console.log("Read Email: ", response.data);
        setSocialData(response.data.user);
        if (response.data.user.id !== null) {
          window.localStorage.setItem("uid", response.data.user.id);
          window.localStorage.setItem("uemail", response.data.user.email);
          window.localStorage.setItem("uispolicy", response.data.user.ispolicy);
          window.localStorage.setItem(
            "ufullname",
            response.data.user.firstname + " " + response.data.user.lastname
          );
          window.localStorage.setItem("logWith", "GOOGLE");
          if (response.data.user.ispolicy !== 0) {
            navigate("/");
          } else {
            navigate("/policy");
          }
          /*         window.localStorage.setItem("id", response.data.user.id);
        window.localStorage.setItem(
          "distributeID",
          response.data.user.distributeID
        );
        window.localStorage.setItem("email", response.data.user.email);
        window.localStorage.setItem(
          "fullname",
          response.data.user.firstname + " " + response.data.user.lastname
        );
        window.localStorage.setItem("owner", owner);
        navigate(`/orderpackage/${id}/${response.data.user.id}/${owner}`); */
        }
      });
  }

  const handleOnSubmit = (data) => {
    console.log("User: ", data);
    axios.post(`${pvuserUrl}login.php`, data).then(function (response) {
      console.log(response.data);
      if (response.data.user.length === 0) {
        console.log("ไม่พบบัญชีผู้ใช้นี้");
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "ไม่พบบัญชีผู้ใช้นี้",
          showConfirmButton: false,
          timer: 1500,
        });
        return;
      }
      if (response.data.status === 1) {
        window.localStorage.setItem("uid", response.data.user.id);
        window.localStorage.setItem("uemail", response.data.user.email);
        window.localStorage.setItem("uispolicy", response.data.user.ispolicy);
        window.localStorage.setItem(
          "ufullname",
          response.data.user.firstname + " " + response.data.user.lastname
        );
        window.localStorage.setItem("logWith", "EMAIL");
        if (response.data.user.ispolicy !== 0) {
          navigate("/");
        } else {
          navigate("/policy");
        }
      }
    });
  };

  useEffect(() => {
    liff.init({ liffId: "2002632700-wPzMX491" });
    /*     if (profile !== null) {
      readEmail(profile?.email);
      console.log("Socail Data: ", socialData);
      if (socialData !== null) {
        console.log("Login Ok");
      }
    } else {
      console.log("No Profile");
    } */
    /*     if (profileFb !== null) {
      readEmail(profileFb?.email);
      console.log("Socail Data: ", socialData);
      if (socialData !== null) {
        console.log("Login Ok");
      }
    } else {
      console.log("No Profile");
    } */
  }, []);

  const handleLineLogin = () => {
    try {
      liff.login();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <div className="max-w-[420px] mx-auto">
        <HeaderHome />
      </div>

      <div className="overflow-y-scroll">
        {/* -------  */}

        <div className="">
          {/* --------  bg-purple-100 py-28*/}

          <div className="relative min-h-screen backdrop-blur flex justify-center items-center bg-texture bg-cover  py-28 sm:py-0">
            <div className="p-4 sm:p-8 flex-1">
              <div className="max-w-[420px] mb-8 flex gap-4 justify-center items-center mx-auto">
                {/*                 <LoginSocialGoogle
                  client_id={clientID}
                  onLoginStart={onLoginStart}
                  scope="openid profile email"
                  discoveryDocs="claims_supported"
                  onResolve={({ provider, data }) => {
                    setProvider(provider);
                    setProfile(data);
                  }}
                  onReject={(err) => {
                    console.log(err);
                  }}
                >
                  <span className="w-[60px] h-[60px] rounded-full flex justify-center items-center bg-slate-600">
                    <img
                      src={GoogleLogo}
                      alt=""
                      className="w-[40px] h-[40px] rounded-sm object-cover"
                    />
                  </span>
                </LoginSocialGoogle> */}
                {/*                 <button
                  onClick={handleLineLogin}
                  className="w-[60px] h-[60px] rounded-full flex justify-center items-center bg-slate-600"
                >
                  <img
                    src={LineLogo}
                    alt=""
                    className="w-[40px] h-[40px] rounded-sm object-cover"
                  />
                </button> */}
                {/*                 <Link
                  to={"/signinLine"}
                  className="w-[60px] h-[60px] rounded-full flex justify-center items-center bg-slate-600"
                >
                  <img
                    src={LineLogo}
                    alt=""
                    className="w-[40px] h-[40px] rounded-sm object-cover"
                  />
                </Link> handleLineLogin*/}

                {/*                <button
                  onClick={() => {}}
                  className="w-[60px] h-[60px] rounded-full flex justify-center items-center bg-slate-600"
                >
                  <img
                    src={GoogleLogo}
                    alt=""
                    className="w-[40px] h-[40px] rounded-sm object-cover"
                  />
                </button>
                <Link
                  to={"/signinLine"}
                  className="w-[60px] h-[60px] rounded-full flex justify-center items-center bg-slate-600"
                >
                  <img
                    src={LineLogo}
                    alt=""
                    className="w-[40px] h-[40px] rounded-sm object-cover"
                  />
                </Link>*/}
              </div>
              <div className="max-w-[420px] min-w-[320px] bg-[#1F212C] border-stroke border-[#504f4f] rounded-lg mx-auto">
                <div className="px-10 pt-8 pb-8 rounded-3xl shadow-xl">
                  <form onSubmit={handleSubmit(handleOnSubmit)}>
                    <div className="">
                      <label className="mb-2.5 block font-medium text-white dark:text-white">
                        อีเมล์ผู้ใช้งาน
                      </label>
                      <input
                        type="email"
                        {...register("email", {
                          required: true,
                        })}
                        className="w-full rounded border-[1.5px] border-stroke bg-[#1F212C] border-[#504f4f]  py-3 px-5 font-medium outline-none"
                        // placeholder="willPig@tailwind.com"
                      />
                    </div>
                    {errors.email && (
                      <div className="my-1 text-normal text-red-500 text-xs italic">
                        ป้อนอีเมล์ผู้ใช้งาน
                      </div>
                    )}
                    <div className="mt-2">
                      <label className="mb-2.5 block font-medium text-white dark:text-white">
                        รหัสผ่าน
                      </label>
                      <input
                        type="password"
                        {...register("password", {
                          required: true,
                        })}
                        className="w-full rounded border-[1.5px] border-stroke bg-[#1F212C] border-[#504f4f]  py-3 px-5 font-medium outline-none"
                        // placeholder="Password"
                      />
                    </div>
                    {errors.password && (
                      <div className="my-1 text-normal text-red-500 text-xs italic">
                        ป้อนรหัสผ่าน
                      </div>
                    )}

                    <button
                      type="submit"
                      className="w-full mt-4 py-3 text-lg text-white font-semibold text-center rounded-sm bg-blue-500 transition-all hover:bg-blue-600 focus:outline-none"
                    >
                      เข้าระบบ
                    </button>

                    <p className="text-center text-sm text-white mt-4">
                      ยังไม่มีบัญชี?{" "}
                      <Link
                        to={"/signup"}
                        className="font-semibold text-white hover:underline"
                      >
                        คลิกสมัครที่นี่
                      </Link>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* --------  */}
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default Signin;
