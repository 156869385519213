import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
} from "../constants/BaseUrl";
const MainOccupationCard = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  return (
    <Link to={`/minoroccupation/${item?.id}/${item.emergency}`}>
      <div className="relative mt-[4.5rem] cursor-pointer rounded-xl shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
        <div className="w-[300px] h-[180px] rounded-lg overflow-hidden">
          <img
            //   /{item.emergency}src={item.image}
            src={`${imgOccUrl}${item.photo}`}
            alt={item.thName}
            className="w-full h-full object-cover"
          />
          <div className="absolute left-0 bottom-2 p-4 bg-[rgba(0,0,0,0.5)] text-center  w-full">
            <strong className="text-lg font-medium text-white">
              {item.thName}
            </strong>
          </div>
        </div>
        {/* <div>{JSON.stringify(item)}</div> */}
      </div>
    </Link>
  );
};

export default MainOccupationCard;
