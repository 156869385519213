import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
} from "../constants/BaseUrl";
import { HeaderBack, MinorOccupationCard } from "../components";
import axios from "axios";
import { GrDocumentPdf } from "react-icons/gr";
import { FaRegStar, FaRegIdCard } from "react-icons/fa";
import { GrDocumentUpdate } from "react-icons/gr";
const UserContact = () => {
  // const { id,  } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const item = location.state;
  // const { comId, type, title } = item;
  // console.log("Hi My Item: ", item);
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    var user = localStorage.getItem("uid");
    // console.log("user: ", user);
    setUserId(user);
    /*     readMinerocc(item?.minorOccId);
    readSocial(item?.id);
    readLogo(item?.memberID); */
  }, []);

  // console.log("Item: ", item);
  //doccontacts
  const gotoDocContact = () => {
    const itemData = {
      comId: item?.id,
      uid: userId,
      memId: item?.memberID,
    };
    navigate("/doccontacts", { state: itemData });
  };

  //PricequoteContact
  const gotoPricequoteContact = () => {
    const itemData = {
      comId: item?.id,
      uid: userId,
      memId: item?.memberID,
    };
    navigate("/pricequotecontact", { state: itemData });
  };
  //sendidcard
  const gotoSendIdCard = () => {
    const itemData = {
      comId: item?.id,
      uid: userId,
      memId: item?.memberID,
    };
    navigate("/sendidcard", { state: itemData });
  };
  //ratings
  const gotoRating = () => {
    const itemData = {
      comId: item?.id,
      uid: userId,
      memId: item?.memberID,
    };
    navigate("/ratings", { state: itemData });
  };

  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        {/* -------  */}

        <div className="mt-[6rem]">
          <div className="grid grid-cols-2 gap-4 px-5 pb-[6rem]">
            {/* -----  */}
            <div className="cursor-pointer bg-[#1E1F20] border border-[#504f4f] p-4 rounded-md shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
              <button
                onClick={gotoPricequoteContact}
                className="flex justify-center flex-col items-center w-full"
              >
                <GrDocumentPdf size={30} />
                <strong className="text-sm font-medium mt-1">
                  ขอใบเสนอราคา
                </strong>
              </button>
            </div>
            <div className="cursor-pointer bg-[#1E1F20] border border-[#504f4f] p-4 rounded-md shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
              <button
                onClick={gotoDocContact}
                className="flex justify-center flex-col items-center w-full"
              >
                <GrDocumentUpdate size={30} />
                <strong className="text-sm font-medium mt-1">
                  ส่งเอกสารจัดจ้าง
                </strong>
              </button>
            </div>
            <div className="cursor-pointer bg-[#1E1F20] border border-[#504f4f] p-4 rounded-md shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
              <button
                onClick={gotoSendIdCard}
                className="flex justify-center flex-col items-center w-full"
              >
                <FaRegIdCard size={30} />
                <strong className="text-sm font-medium mt-1">
                  แนบบัตรประชาชน
                </strong>
              </button>
            </div>
            <div className="cursor-pointer bg-[#1E1F20] border border-[#504f4f] p-4 rounded-md shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
              <Link
                to={`/ratings/${item?.memberID}/${item?.id}`}
                className="flex justify-center flex-col items-center w-full"
              >
                <FaRegStar size={30} />
                <strong className="text-sm font-medium mt-1">Ratings</strong>
              </Link>
            </div>
            {/* -----  */}
          </div>
        </div>
        <div className="mt-[200px]"></div>
        {/* -------  */}
      </div>
    </>
  );
};

export default UserContact;
