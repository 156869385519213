import React, { useState, useEffect } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  pvshopUrl,
} from "../constants/BaseUrl";
import {
  HeaderBack,
  MinorOccupationCard,
  ProviderEmergencyCard,
} from "../components";
import axios from "axios";

const ProviderEmergencyList = () => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [companyInfoData, setCompanyInfoData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const place =
    "Amphoe Mueang Chiang Mai, Chang Wat Chiang Mai 50300, Thailand";
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  function readCompany(id, searchPlace) {
    setIsLoading(true);
    //export const searchcompanyUrl = `${url}searchcompany/`;
    //calculatedistancewithzipcode.php
    //calculatedistance.php
    // axios
    // .get(`${searchcompanyUrl}calculatedistancewithzipcode.php/${id}`)
    //export const pvshopUrl = `${url}pvshop/`;
    axios
      .post(`${pvshopUrl}getcompanyemergency.php`, {
        searchText: searchPlace,
        id: id, //currentPlace?.description
      })
      .then(function (response) {
        // console.log("Cal---------");
        /*         const data = response.data;
        console.log("Type: ", typeof data);
        console.log("companyInfoData::: ", response?.data);
        console.log("---------Cal"); */
        setCompanyInfoData(response.data);
        setIsLoading(false);
      });
  }

  useEffect(() => {
    readCompany(id, place);
  }, []);

  return (
    <>
      <HeaderBack />
      <div className="overflow-y-scroll mx-2">
        <div className="mt-[4.5rem]">
          <div className="grid grid-cols-1 gap-4 mx-5 pb-[6rem]">
            {Object.keys(companyInfoData).length !== 0 ? (
              <>
                {companyInfoData.map((item, idx) => (
                  <ProviderEmergencyCard
                    key={`minor_${idx}`}
                    item={item}
                    width={windowSize[0]}
                  />
                ))}
              </>
            ) : (
              <>
                <div
                  className={`w-[${windowSize[0]}px] h-[${
                    windowSize[1] * 0.5
                  }px] rounded-lg overflow-hidden relative`}
                >
                  <img
                    src={require("../assets/images/no-8.png")}
                    alt={""}
                    className={`w-[${windowSize[0]}px] h-[${
                      windowSize[1] * 0.5
                    }px] object-cover`}
                  />
                  <div className="absolute left-0 bottom-1 p-4 bg-[rgba(0,0,0,0.5)] text-center  w-full">
                    <strong className="text-sm text-white">
                      ไม่มีบริการ-{id}
                    </strong>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-[200px]"></div>
      </div>
    </>
  );
};

export default ProviderEmergencyList;
