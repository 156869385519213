import React, { useState, useEffect, useContext } from "react";
// import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Link,
  useLocation,
  useNavigate,
  NavLink,
  useParams,
} from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  pvshopUrl,
  searchcompanyUrl,
} from "../constants/BaseUrl";
import {
  HeaderBack,
  MinorOccupationCard,
  ProviderEmergencyCard,
  ProviderCard,
  LogoCard,
} from "../components";
import axios from "axios";
import { UserLocationContext } from "../context/UserLocationContext";
import { IoLocation } from "react-icons/io5";
const ProviderList = () => {
  const { id } = useParams();
  // const location = useLocation();
  const navigate = useNavigate();
  // const { pathname } = location;
  const [companyInfoData, setCompanyInfoData] = useState([]);
  const [companyLocInfoData, setCompanyLocInfoData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  /*   const place =
    "Amphoe Mueang Chiang Mai, Chang Wat Chiang Mai 50300, Thailand"; */
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [geocoding, setGeocoding] = useState(null);
  const longdo_key = "6b75448d3c0808af052906060b770d1c";
  const { location, setLocation } = useContext(UserLocationContext);

  function readCompany(id, searchPlace) {
    setIsLoading(true);
    axios
      .post(`${pvshopUrl}readcompanywithpostcode.php`, {
        postcode: searchPlace,
        id: id, //currentPlace?.description
      })
      .then(function (response) {
        /*         console.log("Cal---------");
        const data = response.data;
        console.log("Type: ", typeof data);
        console.log("companyInfoData::: ", response?.data);
        console.log("---------Cal"); */
        setCompanyInfoData(response.data);
        setIsLoading(false);
      });
  }

  function readCompanyLocation(id, condition) {
    // setIsLoading(true);

    axios
      .post(`${searchcompanyUrl}calculatedistancewithpostcode.php`, {
        postcode: condition,
        id: id, //currentPlace?.description
      })
      .then(function (response) {
        /*         console.log('Cal---------');
        const data = response.data;
        console.log('Type: ', typeof data);
        console.log('companyInfoData::: ', response?.data);
        console.log('---------Cal'); */
        setCompanyLocInfoData(response.data);
        setIsLoading(false);
      });
  }
  const readReverseGeocoding = () => {
    axios
      .get(
        `https://api.longdo.com/map/services/address?lon=${location.longitude}&lat=${location.latitude}&noelevation=1&key=${longdo_key}`
      )
      .then(function (response) {
        // console.log("Reverse Geocoding: ", response.data);
        setGeocoding(response.data);
        /*         readProviceCondition(response.data?.postcode);
        readUnitData(response.data?.postcode); */
        readCompanyLocation(id, response.data?.postcode);
        readCompany(id, response.data?.postcode);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    // readCompany(id, place);
    if (location) {
      readReverseGeocoding();
    }
    // readCompanyLocation(id, place);
  }, [location]);
  // console.log("IDDDDD: ", id);
  // console.log("Location: ", location);
  return (
    <>
      <HeaderBack />
      <div className="mt-[5rem] mx-6 flex items-center">
        <IoLocation size={20} color={"red"} />
        <strong className="text-white">
          {geocoding?.province} {geocoding?.postcode} {geocoding?.country}
        </strong>
      </div>
      <div className="overflow-y-scroll mx-2">
        <div className="mt-[1rem]">
          <div className="grid grid-cols-1 gap-4 mx-5 pb-[6rem]">
            {Object.keys(companyInfoData).length !== 0 ? (
              <>
                {companyInfoData.map((item, idx) => (
                  <ProviderCard
                    key={`minor_${idx}`}
                    item={item}
                    width={windowSize[0]}
                  />
                ))}
              </>
            ) : (
              <>
                <div
                  className={`w-[${windowSize[0]}px] h-[${
                    windowSize[1] * 0.5
                  }px] rounded-lg overflow-hidden relative`}
                >
                  <img
                    src={require("../assets/images/no-8.png")}
                    alt={""}
                    className={`w-[${windowSize[0]}px] h-[${
                      windowSize[1] * 0.5
                    }px] object-cover`}
                  />
                  <div className="absolute left-0 bottom-1 p-4 bg-[rgba(0,0,0,0.5)] text-center  w-full">
                    <strong className="text-sm text-white">
                      ไม่มีบริการ-{id}
                    </strong>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="mt-[200px]"></div>
      </div>
    </>
  );
};

export default ProviderList;
