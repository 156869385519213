import React, { useEffect } from "react";
import { Link, useLocation, useNavigate, NavLink } from "react-router-dom";
import {
  proviceUrl,
  pvuserUrl,
  imgOccUrl,
  providerUrl,
  imgProvUrl,
} from "../constants/BaseUrl";

const AdvertProvider = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  return (
    <>
      <div className="relative mt-[1rem] cursor-pointer border border-[#504f4f] rounded-lg shadow-[0px_4px_14px_rgba(0,0,0,0.04)]">
        <a href={item?.url} target="_blank" className="w-[70px] items-center">
          <div className="w-[150px] h-[220px] rounded-lg overflow-hidden">
            <img
              //   src={item.image}
              src={`${imgProvUrl}${item.photo}`}
              alt={item.thName}
              className="w-full h-full object-cover"
            />
            {/*           <div className="absolute left-0 bottom-1 p-4 bg-[rgba(0,0,0,0.5)] text-center  w-full">
        <strong className="text-sm text-white">{item.thName}</strong>
      </div> */}
          </div>
        </a>
        {/* <div>{JSON.stringify(item)}</div> */}
      </div>
    </>
  );
};

export default AdvertProvider;
